@media (min-width: 992px) {
    .navbar .navbar-toggler {
        display: none;
    }

    .navbar-expand-lg>.container {
        flex-wrap: wrap;
    }

    .modal-dialog.modal-md {
        max-width: 590px;
    }
}

@media screen and (max-width: 1499px) {
    .home-artboxy .heading h2 {
        font-size: 30px;
    }

    .sub-header.artwork-head h2 {
        font-size: 28px;
    }

    .contact-advisor .actions {
        width: 66%;
        padding: 70px 40px;
    }
    .contact-advisor .title h2{
        font-size: 40px;
    }
}

@media screen and (max-width: 1299px) {
    .container {
        max-width: 90%;
    }
    .contact-advisor .actions > button{
        height: 120px;
        font-size: 22px;
    }
    .icon.icon-email{
        width: 53px;
        height: 53px;
    }
    .icon.icon-chat {
        width: 43px;
        height: 43px;
    }
    .icon.icon-phone {
        width: 33px;
        height: 33px;
    }
    .contact-advisor .title h2{
        font-size: 38px;
    }
}

@media (max-width: 1199px) {
    .container {
        max-width: 95%;
    }

    .nav-brand img {
        max-width: 300px;
    }

    ul.navbar-nav li {
        margin-right: 20px;
    }

    form.form-inline input {
        min-width: auto;
    }

    .home-collection ul li {
        max-width: 25%;
    }

    .footer-menu ul li {
        padding: 0 10px 10px;
    }

    .store-app a {
        padding-left: 10px;
    }

    .artlist-category {
        flex-wrap: wrap;
    }

    .profile-tabs .nav-tabs a {
        margin-right: 40px;
    }

    .exhibition-detail img {
        max-height: 300px;
        min-height: 300px;
    }

    .about-banner img {
        max-width: 80%;
    }

    .about-banner h2 {
        font-size: 50px;
    }

    .cont-text h2 {
        font-size: 30px;
    }

    .gallery-cont {
        flex-wrap: wrap;
    }

    .breadcrumb-name span {
        font-size: 16px;
    }

    .subscribe-us {
        max-width: 35%;
        padding: 0 20px;
    }

    .standard-tab table.table {
        max-width: 65%;
    }

    .platform ul li {
        max-width: calc(33% - 20px);
    }
    .contact-advisor .actions .icon{
        margin-right: 15px;
    }
    .icon.icon-email{
        width: 43px;
        height: 43px;
    }
    .icon.icon-chat,
    .icon.icon-phone {
        width: 28px;
        height: 28px;
    }
   

    .contest-list .contest-tab .nav-item {
        width: auto;
    }

    .contact-advisor-modal .modal-dialog.modal-md{
        max-width: 700px;
    }

    .contact-advisor .title {
        padding: 20px 40px;
    }

    .contact-advisor .title h2 {
        font-size: 32px;
    }
    .contact-advisor .actions {
        padding: 40px 20px;
    }
    .contact-advisor .actions > button {
        height: 100px;
        font-size: 18px;
    }
}

@media (max-width: 991px) {
    .search-tab {
        width: 100% !important;
        flex-flow: column wrap;
        border: 0 !important;
    }

    .menubar .search-tab form.form-inline {
        padding: 5px 20px;
    }

    .search-tab nav.navbar-nav.nav-tabs {
        justify-content: flex-start;
        flex-direction: column;
        max-width: 100%;
        border: 0;
    }

    .search-tab nav.navbar-nav.nav-tabs a.active {
        border-bottom: 0;
    }

    .search-tab nav.navbar-nav.nav-tabs a {
        padding: 12px 20px;
        border: 0;
    }

    .language-select.desktop-hide button#dropdown-item-button {
        padding: 0 20px !important;
    }

    .navmenu-profile {
        padding-bottom: 0;
    }

    .artwork_single {
        padding: 0 15px;
    }

    .profile-img {
        text-align: left;
    }

    .main-wrapper {
        padding-top: 200px;
    }

    .desktop-hide {
        display: block;
        padding-left: 0;
    }

    .user-mobile {
        display: block;
        text-align: right;
    }

    .mobile-hide {
        display: none;
    }

    ul.login_register {
        display: none;
    }

    .dropdown-menu.loginmenu .dropdown-item {
        padding: 6px 0;
    }

    .language-select button#dropdown-item-button {
        /* padding-left: 20px !important; */
        width: 100%;
        text-align: left;
        background-position: 93% 4px !important;
        background-size: 13px !important;
    }

    ul.navbar-nav button {
        width: 100%;
        text-align: left;
        padding: 15px 20px !important;
    }

    nav.navbar .main-header {
        align-items: center;
        padding: 8px 5px;
    }

    .nav-brand img {
        max-width: 300px;
        width: 100%;
    }

    .menubar {
        display: block;
        background: #fff;
        position: absolute;
        z-index: 999;
        max-width: 255px;
        box-shadow: 0px 10px 15px #2c251b26;
        max-height: 90vh;
        overflow-y: auto;
    }

    .menubar ul.navbar-nav li {
        display: block;
        margin: 0;
        background: #E9E9E9;
        border: 1.5px solid #fff;
        border-left: 0;
        border-right: 0;
    }

    .menubar ul.navbar-nav li>a {
        position: relative;
        padding: 15px 20px !important;
    }

    ul.navbar-nav .dropdown-menu.dropdown-menu {
        box-shadow: none;
    }

    .language-select.desktop-hide {
        background: #E9E9E9;
        padding: 16px 0;
    }

    .menubar form.form-inline {
        background: #AF0612;
        padding: 10px 20px;
        border-top: 2px solid #fff;
    }

    .menubar form.form-inline input.form-control {
        background: transparent;
        border-bottom: 1px solid #fff !important;
        color: #fff !important;
    }

    .menubar form.form-inline input.form-control::placeholder {
        color: #fff;
    }

    .menubar form.form-inline button.btn-search {
        left: 20px;
        color: #fff !important;
        top: 15px;
    }

    .navbar-nav .dropdown-menu {
        position: relative;
        top: 0 !important;
    }

    .menubar ul.navbar-nav li .dropdown-toggle:before {
        content: "";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        position: absolute;
        right: 20px;
        background-image: url(../images/arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 13px;
        height: 13px;
        top: 19px;
    }

    /* .menubar ul.navbar-nav .show.dropdown .dropdown-toggle:before {
        content: "\f077";
    } */
    .menubar .dropdown.desktop-hide {
        padding: 0 20px;
        border-bottom: 1px solid #fff;
        padding-bottom: 20px;
    }

    .language-select.desktop-hide .dropdown-menu {
        position: relative;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        top: 20px;
    }

    .navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu {
        left: 0 !important;
    }

    .login-user .follow-artist {
        justify-content: flex-start;
    }

    .breadcrumb-name {
        text-align: left;
        padding-left: 20px;
        width: 100%;
    }

    .breadcrumb {
        position: relative;
        top: 0;
        display: flex;
        margin: 5px 0;
        align-items: center;
    }

    /* home */
    .home-collection ul li {
        max-width: 33.3333%;
    }

    .home-collection ul li img {
        max-height: 450px;
        min-height: 450px;
    }

    .artlist-img img {
        height: 300px;
    }

    .profile-img .user-icon img {
        width: 100px !important;
        height: 100px !important;
    }

    .profile-img .user-icon,
    .profile-img .user-icon:before,
    .profile-img .user-icon::after {
        width: 112px;
        height: 112px;
    }

    .profile-artwork .artboard img {
        height: 300px;
    }

    .profile-tabs .nav-tabs a.active:before {
        height: 4px;
        top: auto;
        bottom: -6px;
    }

    .radio-img .submitimg {
        margin: 20px;
    }

    .radio-img .submitimg img {
        width: 100%;
        height: 200px;
    }

    .artwork_single .share {
        position: relative;
        top: 0;
        text-align: end;
        margin: 20px 0 30px;
    }

    .artwork_single .share li {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 15px;
    }

    .fullwidth-container {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0%;
        right: 0%;
        margin-left: 0vw;
        margin-right: 0vw;
        overflow: hidden;
    }

    .wrapper-fullwidth-video {
        height: 100%;
        position: relative;
        top: 0;
        width: 100%;
        display: block;
    }

    .wrapper-fullwidth-video video {
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .modal-dialog {
        max-width: 600px;
    }

    .exhibition-accordian .card .card-header {
        padding: 25px 20px;
    }

    .exhibition-accordian .card .card-header h5 {
        font-size: 20px;
    }

    .exhibition-accordian .card .card-body .col-md-4 {
        text-align: center;
    }

    .exhibition-accordian .card {
        margin-bottom: 25px;
    }

    label.check-artwork img {
        max-height: 250px;
    }

    .left-block ul li {
        font-size: 16px;
        padding: 10px 0;
    }

    .right-block p {
        margin-bottom: 25px;
    }

    .right-block h1 {
        margin-bottom: 30px;
        font-size: 45px;
    }

    .right-block h3 {
        font-size: 28px;
    }

    .right-block .accordion .card-header h5 {
        font-size: 18px;
    }

    .right-block .accordion .card-header {
        padding: 15px 20px;
    }

    .right-block h2 {
        font-size: 30px;
    }

    .right-block ul li {
        margin-bottom: 10px;
    }

    .video-partner iframe {
        height: 100%;
        min-height: 300px;
    }

    .video-main .video {
        min-height: 300px;
    }

    .order_summary h3,
    .order_summary table.table tfoot td {
        font-size: 20px;
    }

    .cards_details .setting-accordian .card-header h5 {
        font-size: 18px;
    }

    .standard-tab {
        flex-direction: column;
        margin-bottom: 30px;
    }

    .subscribe-us {
        max-width: 100%;
        padding: 0;
    }

    .standard-tab table.table {
        max-width: 100%;
    }

    .home-artboxy .heading h2 {
        font-size: 28px;
    }

    .painting-masonry.painting {
        padding-bottom: 40px;
        margin-bottom: 40px;
    }

    .gallery-contest {
        max-width: 90%;
    }

    .contest-list .contest-tab {
        padding: 30px 0 45px;
        gap: 15px 40px;
    }

    .list-content {
        gap: 40px 30px;
    }

    .contests--box {
        max-width: 47%;
    }
}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
    }

    .sub-header.artwork-head h2 {
        font-size: 22px;
    }

    nav.navbar .main-header .dropdown {
        margin-bottom: 0;
    }

    .main-header .dropdown-menu {
        left: 0;
        min-width: 250px;
    }

    header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link {
        padding: 9px 20px;
        font-size: 14px;
    }

    header .navmenu-profile .navbar-collapse .navbar-nav button.logout {
        padding: 18px 20px !important;
        font-size: 15px;
    }

    header.header .user-mobile svg {
        width: 24px;
        height: 24px;
    }

    .slider-artworkdetails .artwork-imglist .check-artwork {
        width: 100%;
        padding: 10px;
        margin: 0;
    }

    aside.emoji-picker-react {
        top: 150px;
    }

    .profile-edit .btn {
        width: 100%;
    }

    .edituserprofile {
        margin: 0;
        margin-bottom: 15px;
    }

    .currentprogress {
        width: 100px;
        height: 100px;
    }

    .currentbar img {
        width: 95px;
        height: 94px;
        line-height: 90px;
        left: 2px;
        top: 3px;
    }

    section.Gallery_partner {
        padding: 30px 0;
    }

    label.check-artwork img {
        max-height: 100%;
    }

    .check-artwork {
        display: block;
    }

    .search-tab {
        flex-direction: column;
    }

    .search-tab form.form-inline input {
        border-bottom: 1px solid #2C251B !important;
    }

    .login-menubar {
        flex-wrap: wrap;
    }

    .breadcrumb a.breadcrumb-img {
        position: relative;
    }

    .artist-section .col-md-4 {
        text-align: center;
    }

    .artist-section .col-md-4 a {
        display: block;
    }

    .home-collection ul li {
        max-width: 50%;
    }

    .footer-logo,
    .footer-menu {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .footer-menu ul li {
        padding: 0 15px 5px 0;
    }

    .store-app {
        text-align: center;
    }

    .store-app a {
        padding-left: 0;
        padding-right: 15px;
    }

    footer.footer {
        padding-bottom: 30px;
    }

    .follow-artist .user-icon img {
        width: 50px;
        height: 50px;
    }

    .follow-artist .user-detail {
        margin-right: 20px;
    }

    .follow-artist img.followimg {
        margin-right: 10px;
    }

    .follow-artist {
        flex-wrap: wrap;
    }

    .follow-artist .user-icon {
        margin-right: 10px;
    }

    .modal-body {
        padding: 60px 40px 0;
    }

    .modal-footer {
        padding: 30px 40px;
    }

    .modal-body p.mbmodal {
        padding-bottom: 50px;
    }

    .painting-content h3 {
        padding-right: 25px;
        padding-bottom: 15px;
        text-align: right;
        padding-left: 10px;
    }

    .painting-content .category {
        padding: 15px 30px;
        text-align: right;
    }

    .painting-content {
        display: block;
        width: 100%;
        text-align: end;
    }

    .paintingbanner-img:before {
        height: 100%;
    }

    .profile-img .user-icon {
        margin-left: 0;
    }

    .profile-submit button.primary-btn {
        max-width: 100%;
        padding: 13px 15px;
    }

    .profile-content ul li {
        padding-right: 15px;
    }

    .profile-name {
        flex-wrap: wrap;
    }

    .profile-artwork .artboard::before,
    .profile-artwork .artboard h5 {
        opacity: 1;
    }

    .profile-artwork .artboard h5 {
        left: 15px;
        bottom: 15px;
    }

    .submit-artwork {
        float: none;
        order: 2;
    }

    /* .profile-tabs {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    } */

    .profile-tabs .tab-content {
        padding: 30px 0;
        order: 3;
    }

    .profile-tabs .nav-tabs {
        padding-bottom: 20px;
        order: 1;
        margin-bottom: 10px;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
    }

    .profile-tabs .nav-tabs a {
        margin-right: 30px;
        font-size: 14px;
    }

    .Profile-modal {
        padding: 30px 40px;
    }

    .profile-follower ul li {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .profilemodal-user img {
        width: 50px;
        height: 50px;
        min-width: 50px;
    }

    .notify-details {
        padding-left: 0;
    }

    .profile_popup button.closeicon {
        right: 0;
        top: -36px;
    }

    .profile_popup .modal-dialog {
        padding-top: 45px;
    }

    .edit-page .form-group {
        margin-bottom: 15px;
    }

    .artwork-end {
        margin-bottom: 60px;
    }

    .radio-img .submitimg img {
        height: 150px;
    }

    .success-modal .modal-body {
        padding: 80px 20px;
    }

    .textRight {
        text-align: left;
        margin-top: 15px;
    }

    .mb45 {
        margin-bottom: 15px;
    }

    .home-collection .slick-slide:hover .head-collection:before {
        opacity: 1;
        height: 100%;
    }

    button.slick-arrow.slick-next {
        right: 0;
    }

    button.slick-arrow.slick-prev {
        left: 0;
        z-index: 8;
    }

    .commentmodal .modal-content .modal-body {
        padding: 30px 0;
    }

    .widthcomment {
        padding: 10px 25px;
    }

    .comment-list {
        margin: 20px 25px;
    }

    .comment-modal h2,
    .like-head {
        max-width: 100%;
        margin: 0 25px 20px;
    }

    .user-list .user-detail .like-comment {
        flex-wrap: wrap;
    }

    .like-comment button {
        margin-bottom: 10px;
    }

    .comment-likes .select {
        font-size: 22px;
    }

    .comment-list a.like {
        width: 26px;
        height: 26px;
        line-height: 21px;
        min-width: 26px;
        font-size: 16px;
    }

    .user-list img {
        width: 40px;
        height: 40px;
        min-width: 40px;
    }

    form.comment-form input {
        padding: 25px 20px 25px 45px;
    }

    button.emoji-btn.btn.btn-primary {
        left: 15px;
        font-size: 22px;
        margin-top: 0;
    }

    form.comment-form button {
        font-size: 24px;
        right: 20px;
        top: 22px;
    }

    .user-comment {
        margin-top: 20px;
    }

    .user-list .user-detail h6 {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .exhibition-detail img {
        max-height: initial;
        min-height: initial;
    }

    header.CMS-header .text-center.logo {
        text-align: left !important;
    }

    header.CMS-header .text-right.text {
        text-align: center;
    }

    header.CMS-header .text-right.text ul.cms-rightmenu li {
        padding: 5px 10px;
    }

    header.CMS-header .text-right.text ul.cms-rightmenu {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .left-block {
        border-right: 0;
    }

    .left-block ul {
        border-bottom: 1px solid #2C251B;
        margin-bottom: 30px;
        padding-bottom: 10px;
    }

    .right-block h2 {
        font-size: 28px;
    }

    .helpcenter-banner {
        padding: 20px 0 40px;
    }

    .right-block p {
        margin-bottom: 15px;
    }

    .right-block h1 {
        margin-bottom: 25px;
        font-size: 35px;
    }

    .right-block h3 {
        font-size: 22px;
    }

    .right-block .accordion .card-header h5 {
        font-size: 16px;
    }

    .right-block .accordion .card-header {
        padding: 15px 30px 15px 20px;
    }

    .right-block h2 {
        font-size: 26px;
    }

    .right-block .accordion .card-header h5::after {
        right: -15px;
    }

    .right-block .accordion .card {
        margin-bottom: 10px;
    }

    .cont-text h2 {
        font-size: 24px;
        letter-spacing: 0;
    }

    .about-banner img {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .about-banner .text {
        position: relative;
    }

    .about-banner {
        flex-wrap: wrap;
    }

    .about-banner h2 {
        font-size: 34px;
    }

    .about-center {
        padding-bottom: 50px;
    }

    .cont-text h3 {
        margin-bottom: 20px;
        font-size: 18px;
    }

    .cont-text {
        padding-bottom: 30px;
    }

    .fourth-about .cont-img {
        padding-bottom: 30px;
    }

    .fourth-about {
        padding: 60px 0;
    }

    .login-menubar form.form-inline {
        width: 100%;
    }

    header.header .main-header .login-user {
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .home-collection button.slick-arrow,
    .artwork-buttons button {
        background-color: #fff !important;
        box-shadow: 0 2px 9px rgb(0 0 0 / 10%) !important;
        border-radius: 100%;
        width: 25px;
        height: 25px;
    }

    .head-collection {
        padding: 20px 10px;
    }

    a.globe-img {
        margin-right: 10px;
    }

    .edit-page {
        padding-bottom: 20px;
    }

    header.header {
        position: relative;
    }

    .main-wrapper {
        padding-top: 0;
    }

    .banner-collection {
        padding: 40px 0 60px;
    }

    .monthly-slider ul.slick-dots li button::before {
        width: 15px;
        height: 15px;
        line-height: 16px;
    }

    .viewWrapSayHi img.imgWaveHand {
        margin-bottom: 15px;
        max-width: 80px;
    }

    .viewWrapSayHi h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .viewWrapSayHi p {
        font-size: 12px;
    }

    .viewWrapSayHi .first-chats {
        max-width: 150px;
    }

    .request-number,
    span.chat-number {
        padding: 4px 10px;
        font-size: 16px;
        min-width: 27px;
        height: 27px;
    }

    .viewWrapSayHi {
        padding: 40px 15px;
    }

    .viewBottom img.gallery_select {
        max-width: 20px;
        margin-right: 15px;
    }

    img.icOpenSticker {
        margin-right: 5px;
        max-width: 22px;
    }

    img.icSend {
        max-width: 26px;
    }

    .chat-left {
        margin-bottom: 40px;
    }

    .premium-view {
        flex-wrap: wrap;
        justify-content: center;
    }

    .notification-viewdata ul li {
        padding: 15px 0;
    }

    .notification-viewdata {
        padding: 30px 0 60px;
    }

    .viewListContentChat {
        max-height: 65vh;
        min-height: auto;
    }

    .header-notification {
        position: absolute;
        top: 5px;
        margin: 0 0 0 10px;
        right: 5px;
    }

    .imgItemRight,
    .imgItemLeft {
        max-width: 100%;
        padding-bottom: 15px;
        max-height: inherit !important;
    }

    .slider-content .dropdown button {
        font-size: 25px;
    }

    .slider-content .dropdown {
        padding: 4px !important;
    }

    .viewChatBoard {
        min-height: auto;
    }

    .edit-artworkmodal .slider-artworkdetails {
        padding: 20px;
    }

    .edit-artworkmodal .slider-artworkdetails .slick-slider {
        margin-bottom: 15px;
    }

    .artwork-imglist label.check-artwork {
        max-width: 50%;
    }

    .artworklist-btn {
        flex-wrap: wrap;
    }

    .home-banner-btn p {
        font-size: 16px;
        margin-bottom: 10px;
        margin-right: 0;
    }

    .home-banner-btn {
        padding: 15px 20px;
    }

    .get-start {
        font-size: 16px;
    }

    .standard-tab table.table td {
        min-width: 55px;
    }

    .about-head {
        margin-top: 0;
    }

    .about-head center {
        text-align: center;
    }

    .whybox iframe {
        min-height: 250px;
    }

    .ul-whybox {
        padding-bottom: 50px;
    }

    .sub-header h5 {
        font-size: 14px;
    }

    .platform ul li {
        max-width: calc(50% - 20px);
    }

    .home-artboxy .heading h2 {
        margin-bottom: 5px;
        font-size: 25px;
    }

    .home-artboxy .heading {
        padding: 60px 20px;
    }

    .footer-menu ul {
        text-align: center;
    }

    .copyrights {
        margin-top: 0;
    }

    .user-biography ul li input,
    .user-biography ul li select.form-control,
    .user-biography ul li textarea {
        margin-bottom: 10px;
    }

    .user-biography ul li>span {
        padding-right: 0;
        min-width: 100%;
        margin-bottom: 5px;
        max-width: 100%;
    }

    span.image-map__map.image-map_map_span-1 {
        height: 40px !important;
        width: 200px !important;
        bottom: 26px;
    }

    .contests-row .col-md-6:first-child:after {
        display: none;
    }

    .contest-gallery-slider {
        padding-bottom: 25px;
    }

    .gallery-contest .heading h3 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .gallery-contest .heading {
        padding-bottom: 30px;
    }

    .contests--box {
        max-width: 420px;
    }

    .contest-list .contest-tab .nav-link {
        font-size: 20px;
    }

    .contests--data p {
        min-height: auto;
    }

    .download-box h5 {
        font-size: 24px;
    }

    .download-box .view-files {
        font-size: 16px;
    }

    .download-pending,
    .download-success {
        min-width: 200px;
        font-size: 18px;
    }

    .download_box h5 {
        font-size: 20px;
    }

    .download-modal.modal .modal-body h2 {
        font-size: 34px;
    }

    .download_box {
        margin-bottom: 25px;
    }
}

@media (max-width: 575px) {
    .follow-head.contest-head {
        margin: 30px 0 30px;
    }

    .contests-button {
        flex-wrap: wrap;
    }

    .painting-masonry .loadmore-btn {
        padding-top: 40px;
    }

    .closebutton {
        right: -1px;
    }

    .home-collection ul li {
        max-width: 100%;
    }

    .home-collection .slick-slide:nth-child(even) .head-collection,
    .home-collection .slick-slide:nth-child(even) .head-collection::before {
        bottom: auto;
        top: 0;
    }

    .modal-body,
    .share_datamodal .modal-content .modal-body {
        padding: 60px 25px 0;
    }

    .share_datamodal button.closebutton {
        right: 20px;
        top: 20px;
    }

    .modal-footer {
        padding: 30px 25px;
    }

    .profile-artwork .artboard img {
        height: 100%;
    }

    .submit-artwork .submit-work {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .submit-artwork .submit-work span::after {
        top: 2px;
    }

    .profile-about {
        margin: 0 auto;
    }

    .profile-about .about-user {
        padding-bottom: 30px;
    }

    .Profile-modal {
        padding: 30px 15px;
    }

    .profile-follower ul li {
        display: block;
    }

    .profile-follower ul li .modal-profile {
        padding-bottom: 15px;
    }

    .editprofile .nav-tabs a,
    .profile-tabs.submit-work .nav-tabs a {
        min-width: auto;
    }

    .profile-tabs.submit-work .nav-tabs {
        max-width: 100%;
        margin: 0;
    }

    .upload img,
    .uploaded-img .upload {
        height: 150px;
    }

    .uploaded-img.row {
        margin-bottom: 35px;
    }

    .image-verify img {
        width: 100%;
        max-width: 60px;
    }

    h6.submit_verify {
        font-size: 18px;
    }

    .radio-img .submitimg img {
        height: 100px;
    }

    .submit-details .form-group {
        margin-bottom: 30px;
    }

    .caption.form-group {
        margin-bottom: 50px;
    }

    .image-checkbox {
        margin-bottom: 40px;
    }

    .radio-img .submitimg {
        margin: 12px;
    }

    .radio-img input:checked+.submitimg::before {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -5px;
        left: -5px;
    }

    .galleryEx {
        flex-wrap: wrap;
    }

    .galleryEx-img {
        margin-right: 0;
        margin-bottom: 20px;
        min-width: 100%;
    }

    .galleryEx-content h6 {
        margin-bottom: 20px;
    }

    .radio-custom-label span.save {
        font-size: 14px;
        position: relative;
        padding-left: 7px;
        top: 0;
    }

    .notification-data ul li .modal-profile {
        flex-wrap: wrap;
    }

    .why-artboxy .artboxy-client-image img {
        max-width: 150px;
    }

    .why-artboxy-review .artboxy-review--content .review_title {
        text-align: center;
        font-size: 16px;
        line-height: 1.2;
    }

    .why-artboxy-review .artboxy-review--content p {
        text-align: center;
        padding-bottom: 10px;
        display: block;
        width: 100%;
    }

    .report-form {
        flex-wrap: wrap;
    }

    .report-form button.btn.primary-btn {
        max-width: 100%;
    }

    .report-commentmodal .modal-body {
        padding: 50px 40px;
    }

    .welcome-btns button.btn.btn-primary {
        width: 100%;
    }

    .navmenu-profile div#navbarScroll {
        min-width: 250px;
    }

    .dropdown-menu.loginmenu.dropdown-menu {
        left: auto;
        right: 0;
        min-width: 150px;
    }

    .header-notification a {
        margin-right: 0;
        margin-left: 10px;
    }

    .platform ul li {
        max-width: 100%;
    }

    .home-box {
        height: auto;
    }

    .home-artboxy .heading h2 {
        margin-bottom: 5px;
        font-size: 23px;
    }

    .follow-head.contest-head .follow-artist {
        justify-content: center;
        gap: 15px 0;
    }

    .download-modal.modal .modal-body {
        padding: 30px;
    }

    .download_box {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .download_box h5 {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .download-modal.modal .modal-dialog {
        max-width: 90%;
    }
}

@media screen and (max-width: 420px) {
    .artwork-imglist label.check-artwork {
        max-width: 100%;
    }

    label.check-artwork .artboard-sliderdata img {
        max-height: 100%;
    }

    .welcome-btns button.btn.btn-primary {
        font-size: 12px;
    }

    .main-header .dropdown-menu {
        min-width: 220px;
    }

    .gallery-select .selectday {
        font-size: 12px;
        padding: 6px 25px 7px 10px;
        background-position-x: calc(100% - 0px);
        width: 100%;
        text-align: left;
        font-weight: 600;
    }

    .gallery-select {
        width: 100%;
    }

    span.image-map__map.image-map_map_span-0 {
        top: 5px !important;
        right: 5px;
        width: 40px !important;
        height: 40px !important;
    }

    span.image-map__map.image-map_map_span-1 {
        height: 30px !important;
        width: 100px !important;
        bottom: 10px;
    }
}

@media (max-width: 1366px) {
    .btn.theme-primary {
        font-size: 18px;
        height: 54px;
    }
    .title.text-2xl,
    .how-to-submit .video-block > div.text h3,
    .how-to-submit .or {
        font-size: 40px;
    }
    .artist-grid .content > h3,
    .advisors-list .col .item-inner .content > h3 {
        font-size: 32px;
    }
    .advisors-grid > p,
    .section-head p,
    .content-heading > p,
    .artist-grid .content > span {
        font-size: 22px;
    }
    .getStarted-row .flex-content .counter {
        font-size: 80px;
    }
    .section-head h2 {
        margin-bottom: 20px;
    }

    .section-getStarted {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-top-slider {
        padding-bottom: 50px;
    }
    .section-how-works {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .section-reasons {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    .section-testimonials,
    .section-social-network {
        padding: 100px 0;
    }

    .artist-grid .content > p {
        padding-right: 0;
    }
    .how-to-submit .video-block > div.text {
        padding: 40px 80px;
    }
    .section-getStarted .actions, .section-reasons .actions {
        max-width: 520px;
    }
    .section-testimonials .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .icon.icon-facebook, .icon.icon-instagram {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 1200px) {
    .btn-link {
        font-size: 18px;
    }
    .artist-grid .content,
    .getStarted-row .flex-content .text,
    .grid-reasons .col .item-inner {
        font-size: 14px;
    }
    .getStarted-row .flex-content .text h3,
    .grid-reasons .col .item-inner h3 {
        font-size: 22px;
    }
    .how-to-submit .video-block {
        margin-bottom: 70px;
    }
    .advisors-list .col .item-inner .image {
        width: 220px;
        height: 220px;
    }
    .advisors-list .col .item-inner .content {
        font-size: 15px;
    }
    .getStarted-row {
        padding: 40px 0 20px 0;
    }
    .getStarted-row .flex-content {
        padding: 25px 0;
        max-width: 240px;
    }
}

@media (max-width: 1100px) {
    .btn.theme-primary {
        font-size: 16px;
        height: 50px;
    }
    .btn-link {
        font-size: 16px;
    }
    .title.text-2xl, 
    .how-to-submit .video-block > div.text h3, 
    .how-to-submit .or {
        font-size: 32px;
    }
    .artist-grid .content > h3, 
    .advisors-list .col .item-inner .content > h3 {
        font-size: 26px;
    }
    .advisors-grid > p, 
    .section-head p, 
    .content-heading > p, 
    .artist-grid .content > span,
    .getStarted-row .flex-content .text h3, 
    .grid-reasons .col .item-inner h3 {
        font-size: 18px;
    }

    .btn-link > i {
        width: 20px;
    }
    .icon.icon-arrow-long {
        background-size: auto 28px;
    }

    .section-top-slider {
        padding-bottom: 40px;
    }
    .section-getStarted,
    .section-how-works,
    .section-reasons  {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .section-testimonials,
    .section-social-network {
        padding: 50px 0;
    }

    .section-top-slider .row {
        margin: 0 -20px;
    }
    .section-top-slider .row > div {
        padding: 0 20px;
    }
    .left-col .content-heading {
        padding-top: 40px;
        max-width: 450px;
    }
    .image-slider .slick-dots li {
        margin: 0 0 0 5px;
    }
    .image-slider .slick-dots li button {
        width: 13px;
        height: 13px;
    }
    .section-getStarted .actions, 
    .section-reasons .actions {
        max-width: 460px;
    }
    .how-to-submit .video-block > div.text {
        padding: 20px 40px;
        max-width: 300px;
        margin: 0 auto;
    }
    .artist-grid .content .btn-link {
        margin-top: 15px;
    }
    .section-testimonials .content-col > .content-inner {
        max-width: 260px;
    }
    .section-testimonials .content-col > .content-inner:before {
        width: 30px;
        height: 30px;
    }
    .artist-grid {
        margin: 0 -10px;
    }
    .artist-grid .item {
        padding: 0 10px;
    }
    .section-social-network .col-image > img {
        max-height: 460px;
    }

    .contact-advisor-modal .modal-header .close {
        width: 34px;
        height: 34px;
    }
}

@media (max-width: 992px) {
    .getStarted-row .flex-content .counter {
        font-size: 60px;
    }
    .advisors-list .col .item-inner .content {
        font-size: 14px;
    }
    .section-head h2 {
        margin-bottom: 15px;
    }
    .getStarted-row .flex-content .text h3 {
        margin-bottom: 5px;
    }
    .advisors-list .col .item-inner .image {
        width: 180px;
        height: 180px;
    }
    .advisors-list .col {
        padding: 0 12px;
    }
    .grid-reasons {
        margin: 30px 0;
    }
    .grid-reasons .col .item-inner {
        padding: 15px;
    }
    .section-testimonials .row > div {
        width: 100%;
    }
    .section-testimonials .content-col{
        margin-bottom: 30px;
    }
    .section-testimonials .content-col > .content-inner {
        max-width: 100%;
        text-align: center;
        padding-top: 40px;
       
    }
    .section-testimonials .content-col > .content-inner .social-links{
        margin-top: 20px;
    }
    .section-testimonials .content-inner .social-links a {
        display: inline-block;
        margin: 0 5px;
    }
    .section-testimonials .content-col > .content-inner:before {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
    }
    .advisors-grid > p {
        max-width: 460px;
        margin: 0 auto;
    }
}


@media (max-width: 767px) {
    .contact-advisor {
        flex-direction: column;
    }
    
    .contact-advisor > div.title,.contact-advisor > div.actions {
        width: 100%;
    }

    .contact-advisor .title h2{
        text-align: center;
        font-size: 24px;
    }
    .title.text-2xl, .how-to-submit .video-block > div.text h3, .how-to-submit .or {
        font-size: 28px;
    }
    .advisors-grid > p, .section-head p, .content-heading > p, .artist-grid .content > span, .getStarted-row .flex-content .text h3, .grid-reasons .col .item-inner h3 {
        font-size: 16px;
    }
    .video-play {
        left: 0;
        right: 0;
    }
    .section-top-slider {
        padding-bottom: 30px;
    }
    .section-getStarted,
    .section-how-works,
    .section-reasons  {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .section-testimonials,
    .section-social-network {
        padding: 40px 0;
    }
    .section-top-slider .row > div.left-col,
    .section-top-slider .row > div.right-col {
        width: 100%;
    }
    .section-top-slider .row > div.left-col {
        padding-bottom: 20px;
        text-align: center;
    }
    .left-col .content-heading {
        padding-top: 0;
    }
    .getStarted-row .col {
        width: 100%;
        flex: auto;
        border: none;
    }
    .getStarted-row .col:not(:first-child) {
        margin-top: 20px;
    }
    
    .getStarted-row .flex-content {
        text-align: center;
        flex-direction: column;
        border: 1px solid #D9D9D9 ;
        max-width: 100%;
    }
    
    .getStarted-row .flex-content .text {
        padding: 0 20px;
        max-width: 250px;
    }
    .how-to-submit .video-block {
        max-width: 100%;
        flex-direction: column;
        margin-bottom: 30px;
    }
    
    .how-to-submit .video-block .video-main {
        width: 100%;
        order: 2;
    }
    .how-to-submit .video-block > div.text {
        max-width: 100%;
        padding: 10px;
        width: 100%;
        text-align: center;
        min-height: 150px;
    }
    .advisors-list .col {
        width: 100%;
    }
    .advisors-list .col:not(:last-child) {
        margin-bottom: 30px;
    }
    .grid-reasons .col {
        width: 100%;
        margin: 0;
        border-left: none;
        border-right: none;
    }
    
    .grid-reasons .col .item-inner {
        text-align: center;
    }
    .artist-grid .item {
        width: 100%;
        margin-bottom: 30px;
    }
    .icon.icon-facebook, .icon.icon-instagram {
        width: 30px;
        height: 30px;
    }
    .section-social-network .social-links > a {
        margin-right: 10px;
    }
    

    /*Subscription css*/
    .flex-row.setting {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .flex-row.setting .nav-item {
        min-width: max-content;
        margin-right: 20px;
    }
    
    .flex-row.setting .nav-item > a {
        font-size: 14px;
    }
    
    .flex-row.setting .nav-item > a.active {
        font-size: 16px;
    }
    
    .tab-heading h2 {
        font-size: 24px;
        margin-bottom: 8px;
    }
    
    .tab-heading p {
        font-size: 14px;
    }
    
    .subscription-tab-nav .nav-item > a {
        font-size: 14px;
        padding: 4px 20px;
    }
    
    .tab-heading {
        margin-bottom: 35px;
        margin-top: 10px;
    }
    table.table.subscription-body tr > td {
        padding: 10px 5px;
    }
    .subscription-content .table td.feature-text {
        font-size: 11px;
        padding-left: 0;
    }
    
    .subscription-content .table th .info .type {
        font-size: 20px;
    }
    
    .subscription-content .table th .info {
        min-height: 50px;
        font-size: 14px;
        line-height: normal;
    }

    .subscription-content .table td.feature-text, 
    .subscription-content .table th.left-blank, 
    .subscription-content .table td.left-blank {
        width: 28%;
    }
    .subscription-content .table tr td:last-child,
    .subscription-content .table tr th:last-child {
        width: 27%;
    }

    .subscription-content {
        margin-top: 30px;
    }
    .subscription-content .table th {
        padding-top: 12px;
    }
    
    .subscription-footer .btn {
        font-size: 11px;
        min-width: inherit;
        padding: 4px 5px;
        line-height: normal;
        letter-spacing: 0;
        height: auto;
        min-height: 45px;
    }
    .icon.icon-cross.black, .icon.icon-check.white {
        width: 20px;
        height: 20px;
        background-size: contain;
    }
    .subscription-content .scroll-container, .subscription-content .table-header, .subscription-content .table-footer {
        padding: 0 8px;
    }
    .subscription-content .table th .info > span.time{
        margin-bottom: 0;
    }
    .active.free-trial > span,
    .subscription-footer .btn > span {
        font-size: 10px;
    }
    .contact-advisor-modal .modal-dialog.modal-md {
        margin: 10px auto;
    }
   
}

@media (max-width: 480px) {
  
    .section-getStarted .actions,
    .section-reasons .actions{
        max-width:250px;
        flex-direction: column;
        padding: 20px 0;
    }
    .section-getStarted .actions .btn,
    .section-reasons .actions .btn {
        margin-bottom: 10px;
    }
    .contact-advisor .actions{
        padding: 20px;
    }

    .contact-advisor-modal .modal-header .close {
        background-color: #fff;
        width: 25px;
        height: 25px;
        top: 0;
        right: 0;
    }
}

@media (max-width: 414px) {
    .subscription-tab-nav .nav-item > a {
        font-size: 12px;
        padding: 4px 12px;
    }
    .subscription-tab-nav .nav-item > a span{
        font-size: 10px;
    }
    .subscription-content .table th .info .type {
        font-size: 16px;
    }
    .subscription-content .table th .info{
        font-size: 11px;
    }
    .subscription-content .table td.feature-text{
        font-size: 10px;
        line-height: 1.2;
    }
    .subscription-content .table th .info{
        min-height: 50px;
    }
    .subscription-footer .btn{
        font-size: 9px;
    }
    .subscription-content .table.subscription-footer td.contact-btn {
        padding-top: 15px;
    }
    .active.free-trial > span, .subscription-footer .btn > span {
        font-size: 8px;
    }
    .subscription-footer .btn{
        min-height: 35px;
    }
    .subscription-content .scroll-container, .subscription-content .table-header, .subscription-content .table-footer{
        padding: 0;
    }
}