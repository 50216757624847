@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
/*------- THEME CSS ---------
font-family: 'Barlow', sans-serif;
*/

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    position: relative;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #2C251B;
    font-family: 'Barlow', sans-serif;
    padding: 0;
    margin: 0 !important;
    letter-spacing: 0.2px;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: #2C251B;
    transition: all 0.3s ease-in-out;
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #AF0612;
}

input,
textarea,
select,
button {
    outline: none !important;
    box-shadow: none !important;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 0 0;
    padding: 0;
    letter-spacing: 0.3px;
    line-height: 1.2;
}

h1 {
    font-size: 45px;
    line-height: 1;
}

h2 {
    font-size: 36px;
    font-weight: bold;
}

h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

h4 {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
}

h5 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
}

h6 {
    font-size: 14px;
    line-height: 18px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: #6D6E72;
    /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
    transition: background-color 5000s ease-in-out 0s;
}

.container {
    max-width: 1250px;
}

/*Global Styling*/
.btn {
    min-width: 200px;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    padding: 15px 20px;
    border-radius: 0;
    letter-spacing: 0.64px;
    font-weight: 800;
    position: relative;
    color: #2C251B;
    outline: none !important;
}

.btn:focus {
    box-shadow: none !important;
}

.primary-btn {
    color: #2C251B;
    border: 2px solid #2C251B;
    background-color: transparent;
    transition: 0.5s;
}

.primary-btn:hover,
.primary-btn:focus {
    color: #fff !important;
    border: 2px solid #2C251B !important;
    background-color: #2C251B !important;
    transition: 0.5s;
}

.primary-btn.disabled,
.btn-primary:disabled {
    color: #2C251B;
    border: 2px solid #2C251B;
    background-color: transparent;
    opacity: 0.3;
}

.secondary-btn {
    background: #f2d1d3;
    border: 0;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
    color: #2C251B;
}

.secondary-btn:hover,
.secondary-btn:focus {
    color: #2C251B !important;
    background: #f2d1d3 !important;
}

.white {
    color: #fff;
}

p {
    margin: 0;
}

/* -------- main css -------- */
.main {
    min-height: 60vh;
}

/* header */
.pd-0 {
    padding: 0;
}

nav.navbar {
    padding: 0;
}

nav.navbar .main-header {
    width: 100%;
    align-items: flex-end;
    border-bottom: 1px solid rgb(44 37 27 / 30%);
    margin: 0;
}

.navmenu-profile {
    padding-bottom: 10px;
}

.nav-brand {
    width: 100%;
    text-align: center;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown,
.dropdown button {
    color: #2C251B !important;
    font-size: 14px;
    line-height: 18px;
    padding: 0 !important;
    letter-spacing: 0.2px;
    background: 0 !important;
    border: 0;
    min-width: auto;
    font-weight: 400;
    box-shadow: none !important;
}

.show>.btn-primary.dropdown-toggle {
    color: #2C251B;
}

.dropdown a i {
    padding-left: 10px;
    color: #AF0612;
}

nav.navbar .main-header .dropdown {
    margin-bottom: 12px;
}

.navbar-brand {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    display: block;
}

ul.login_register {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

ul.login_register li a {
    border: 1px solid #2C251B;
    border-collapse: collapse;
    padding: 12px 32px;
    font-weight: 600;
    transition: all 0.3s linear;
    display: inline-block;
}

ul.login_register li:last-child a {
    border-left: 0;
}

ul.login_register li a:hover,
ul.login_register li a:focus {
    background-color: #2C251B;
    color: #fff;
}

.language-select button {
    text-transform: none;
}

.language-select button#dropdown-item-button {
    background-image: url(../images/arrow.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    padding-right: 30px !important;
}

.dropdown-menu {
    box-shadow: 0px 10px 15px #2c251b26;
    border: 0;
    padding: 15px 20px;
    border-radius: 0;
    min-width: 200px;
    max-height: 600px;
    overflow-y: auto;
}

.language-select .dropdown-menu span.dropdown-item-text {
    color: #AF0612;
    border-bottom: 1px solid rgb(156 172 185 / 50%);
    margin-bottom: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 0;
    padding-bottom: 15px;
    position: relative;
    padding-right: 25px;
}

.language-select .dropdown-menu button.dropdown-item {
    margin-bottom: 24px;
    font-weight: 600;
}

.language-select .dropdown-menu button.dropdown-item span {
    display: block;
    font-weight: 400;
}

.language-select .dropdown-menu span.dropdown-item-text svg {
    position: absolute;
    right: 0;
    font-size: 25px;
    top: 0;
}

.menubar {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

ul.navbar-nav {
    display: block;
}

ul.navbar-nav li {
    display: inline-block;
    margin-right: 45px;
}

ul.navbar-nav li:last-child {
    margin: 0;
}

ul.navbar-nav li>a {
    padding: 10px 0 !important;
    text-transform: uppercase;
    transition: 0.2s;
    display: inline-block;
    color: #2C251B;
}

ul.navbar-nav li>a.active {
    background: #f2d1d3;
    font-weight: 500;
    color: #2C251B;
}

ul.navbar-nav li>a:hover,
ul.navbar-nav li>a:focus {
    color: #2C251B;
}

ul.navbar-nav li:first-child>a {
    padding: 10px 0 !important;
}

form.form-inline input {
    border: 0;
    border-bottom: 1px solid #2C251B !important;
    border-radius: 0;
    padding: 8px 8px 8px 25px;
    color: #2C251B;
    font-size: 14px;
    min-width: 150px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
}

form.form-inline input::placeholder {
    color: #2C251B;
}

form.form-inline {
    position: relative;
}

form.form-inline .btn-search {
    background: 0;
    border: 0;
    padding: 0;
    position: absolute;
    left: 0;
    width: auto;
    min-width: auto;
    top: 8px;
}

.navbar-nav .dropdown-menu {
    padding: 0;
    top: 30px !important;
    left: 0px !important;
}

nav.navbar .navbar-nav .dropdown-menu a.dropdown-item {
    border: 1px solid rgb(112 112 112 / 05%);
    border-left: 0;
    border-right: 0;
    padding: 10px 20px !important;
    text-transform: capitalize;
    color: #2C251B !important;
    font-weight: 400;
    transition: all 0.3s;
}

nav.navbar .navbar-nav .dropdown-menu a.dropdown-item:hover,
nav.navbar .navbar-nav .dropdown-menu a.dropdown-item:focus,
nav.navbar .navbar-nav .dropdown-menu a.ective {
    background-color: #f2d1d3;
    font-weight: 500;
}

nav.navbar .navbar-nav .dropdown-menu a {
    color: #2C251B !important;
}

nav.navbar .navbar-nav .dropdown-menu a.dropdown-item:first-child {
    border-top: 0;
}

.navbar-nav .dropdown-menu:before {
    content: "";
    background: #AF0612;
    height: 3px;
    width: 75px;
    position: absolute;
    top: 0;
    left: 0;
}

button.navbar-toggler {
    border: 0;
    padding: 0;
    outline: none !important;
    border-radius: 0;
    position: relative;
    z-index: 9999999;
}

.bar1,
.bar2,
.bar3 {
    width: 22px;
    height: 2px;
    background-color: #2C251B;
    margin: 0 0 4px 0;
    transition: 0.4s;
    border-radius: 32px;
}

.bar3 {
    margin: 0 auto;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-4px, 4px);
    transform: rotate(-45deg) translate(-4px, 4px);
    background-color: #fff;
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -7px);
    transform: rotate(45deg) translate(-8px, -7px);
    background-color: #fff;
}

.user-mobile {
    display: none;
    transition: all 0.4s ease-in-out;
}

.user-mobile svg {
    padding: 0;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    text-align: center;
}

.user-mobile:hover i {
    color: #AF0612;
}

.desktop-hide {
    display: none;
}

button#dropdown-login {
    margin-left: auto;
}

.login-menubar svg {
    color: #2C251B;
}

.header-notification {
    position: relative;
    top: 5px;
    margin: 0 5px 0 10px;
    display: flex;
    align-items: center;
    z-index: 999;
}

.header-notification a {
    margin-right: 15px;
    position: relative;
}

.header-notification svg {
    color: rgb(44 37 27 / 35%);
    font-size: 22px;
}

.header-notification span.alert-notify {
    background: #AF0612;
    border: 1px solid #FF3D4B;
    width: 9px;
    height: 9px;
    display: block;
    border-radius: 100%;
    position: absolute;
    right: -3px;
    box-shadow: 0 2px 6px #e86f77;
    top: -1px;
}

/* ----------- main page css ----------- */
.banner-collection {
    padding: 20px 0 60px;
}

.home-collection .slick-slide {
    padding: 0 12px;
}

.slider-item {
    margin-bottom: 25px;
    position: relative;
}

.slider-item img,
.slider-bg {
    width: 100%;
    object-fit: cover;
    max-height: 500px;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slick-prev:before {
    content: "";
}

.slick-prev {
    background-image: url(../images/arrow.svg) !important;
    background-repeat: no-repeat !important;
    width: 25px;
    height: 25px;
    position: absolute;
    transform: rotate(90deg);
    background-position: center !important;
}

.slick-next:before {
    content: "";
}

.slick-next {
    background-image: url(../images/arrow.svg) !important;
    background-repeat: no-repeat !important;
    width: 25px;
    height: 25px;
    position: absolute;
    transform: rotate(-90deg);
    background-position: center !important;
}

.home-collection .slider-item a {
    width: 100%;
}

button.slick-arrow:before {
    color: #000;
}

button.slick-arrow:hover::before {
    color: #AF0612 !important;
}

.head-collection {
    position: absolute;
    width: 100%;
    padding: 28px 10px;
    text-align: center;
    transition: 0.2s;
    top: 0;
}

.head-collection:before {
    content: "";
    background: #f2f1ef;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s ease-out;
}

.home-collection .slick-slide:hover .head-collection:before {
    opacity: 0;
    height: 450px;
}

.home-collection .slick-slide:hover .head-collection {
    padding: 18px 10px;
}

.home-collection .slick-slide:nth-child(even) .head-collection,
.home-collection .slick-slide:nth-child(even) .head-collection::before {
    bottom: 0;
    top: auto;
}

.head-collection h4 {
    color: #2c251b;
    text-transform: uppercase;
    letter-spacing: 1.28px;
    position: relative;
    font-weight: 600;
}

.home-banner-btn img.dots {
    max-height: 12px;
    margin: 0 10px;
}

.home-banner-btn {
    display: flex;
    align-items: center;
    border: 2px solid #AF0612;
    color: #AF0612;
    padding: 18px 25px;
    border-radius: 5px;
    flex-wrap: wrap;
    margin: 0 10px;
}

.home-banner-btn p {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    display: inline-block;
    line-height: 1.1;
    margin-right: 40px;
}

.home-banner-btn>a {
    color: #AF0612;
}

.get-start {
    font-size: 22px;
    font-weight: 500;
    text-decoration: underline;
}

/* footer */
.footer-menu ul {
    text-align: center;
}

.footer-menu ul li {
    display: inline-block;
    padding: 8px 12px 0;
}

.footer-menu ul li a {
    letter-spacing: 0.2px;
    font-size: 13px;
}

.store-app {
    text-align: right;
}

.store-app a {
    padding-left: 26px;
    padding-top: 8px;
}

.copyrights {
    text-align: center;
    margin-top: 30px;
}

.copyrights p {
    font-size: 10px;
    letter-spacing: 0.14px;
}

footer.footer .row {
    border-top: 1px solid #D5D5D5;
    padding-top: 25px;
    align-items: center;
}

footer.footer {
    padding-bottom: 50px;
}

/* ============= artists page =========== */
section.artist {
    padding: 35px 0 80px;
}

.follow-artist {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.follow-artist .user-icon {
    margin-right: 15px;
    border-radius: 100%;
    background: #BCBBBB;
    padding: 2px;
    position: relative;
    z-index: 1;
}

.follow-artist .user-icon img {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid #fff;
}

.follow-artist .user-icon::before {
    vertical-align: text-bottom;
    border: 2px solid #E0761E;
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
    content: "";
}

.user-detail h6 {
    font-weight: 600;
    margin: 0 !important;
}

.user-detail p {
    font-size: 12px;
}

.follow-artist .user-detail {
    margin-right: 45px;
}

.follow-artist img.followimg {
    margin-right: 25px;
    max-width: 30px;
    max-height: 30px;
}

.follow-artist .follow-text {
    color: #555555;
    font-weight: 500;
    opacity: 0.7;
    position: relative;
    transition: 0.2s;
    background: transparent;
    border: 0;
    outline: none !important;
    min-width: auto;
    height: auto;
    padding: 0;
    padding-left: 10px;
    text-transform: none;
}

.follow-artist .follow-text:before {
    content: "";
    height: 5px;
    width: 5px;
    background: #555555;
    position: absolute;
    border-radius: 100%;
    top: 8px;
    left: 0;
    opacity: 0.7;
    transition: 0.2s;
}

.follow-artist .follow-text:hover,
.follow-artist .follow-text:hover::before {
    opacity: 1;
}

.follow-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.follow-head .link-icon {
    opacity: 0.3;
    min-width: auto;
    padding: 0;
    border: 0;
    background: transparent !important;
}

.price-icon {
    background: transparent !important;
    padding: 0;
    border: 0;
    min-width: auto;
}

.artlist-img {
    position: relative;
}

.artlist-img:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.75;
    z-index: 1;
    background: transparent linear-gradient(2deg, #2C251B 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
}

.artlist-img img {
    height: 370px;
    object-fit: cover;
    position: relative;
    width: 100%;
    object-position: center;
    background: burlywood;
}

.artlist-img h5 {
    position: absolute;
    bottom: 12px;
    color: #fff;
    left: 20px;
    z-index: 1;
}

.artlist-category {
    padding: 10px 0 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.artlist-category h6 {
    color: #AF0612;
    font-weight: 600;
}

.artist-list {
    margin-bottom: 30px;
}

.border-bottom {
    border-bottom: 1px solid #AF0612 !important;
    padding-bottom: 10px;
    margin-bottom: 60px;
}

.login-register {
    text-align: center;
    padding-top: 30px;
}

.login-register a {
    font-weight: 600;
}

.otp-logins>div {
    justify-content: space-between;
    margin-bottom: 10px;
}

.follow-artist .follow-text.active {
    color: #AF0612;
    opacity: 1;
    min-width: auto;
    width: auto;
}

.follow-artist .follow-text.active::before {
    background: #AF0612;
    opacity: 1;
}

/* modal css */
.fade.modal-backdrop {
    background: #2C251B;
    opacity: 0.6;
}

.modal-content {
    background: rgb(255 255 255 / 90%);
    box-shadow: 0px 5px 30px #2c251b26;
    border-radius: 5px;
    opacity: 1;
    border: 0;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}

.modal-body {
    padding: 70px 70px 0;
    text-align: center;
}

.modal-body h6 {
    font-weight: 600;
    margin-bottom: 15px;
}

.modal-body a {
    color: #AF0612;
    font-size: 20px;
}

.modal-body p.mbmodal {
    max-width: 310px;
    margin: 0 auto;
    padding-bottom: 70px;
}

.modal-body button {
    width: 100%;
}

.modal-footer {
    display: block;
    text-align: center;
    padding: 45px 70px;
    border: 0;
}

.modal-footer button {
    background: transparent !important;
    border: 0;
    padding: 0;
    margin: 0;
    text-transform: none;
    font-size: 14px;
    outline: none;
    color: #212529 !important;
}

.fade.modal {
    padding: 0 15px !important;
}

body.modal-open {
    padding: 0 !important;
    overflow: hidden;
}

/* Breadcrumb */
.breadcrumb {
    margin: 0;
    position: absolute;
    width: 100%;
    display: block;
    top: 15px;
    min-height: 25px;
}

.breadcrumb-img.btn.btn-primary {
    padding: 0;
    margin: 0;
    min-width: auto;
    border: 0;
    position: absolute;
    background: transparent;
    left: 0;
}

.breadcrumb-img.btn.btn-primary img {
    transform: rotate(90deg);
    position: relative;
    top: -2px;
}

.breadcrumb-name span {
    color: #AF0612;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
}

.breadcrumb-name {
    text-align: center;
}

.breadcrumb a.breadcrumb-img {
    position: absolute;
    transform: rotate(90deg);
}

.breadcrumb a.breadcrumb-img img {
    height: 7px;
}

.login-menubar {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 25px;
}

/* profile Header */
.login-user .follow-artist .user-icon img {
    width: 26px;
    height: 26px;
}

.login-user .follow-artist .user-icon {
    margin-right: 11px;
}

.login-user .follow-artist .user-detail {
    margin-right: 11px;
}

.login-user {
    margin-bottom: 17px;
}

.login-user .follow-artist {
    justify-content: flex-end;
    position: relative;
}

.login-user .follow-artist img.followimg {
    margin-right: 0;
}

.login-user .follow-artist .user-icon::before {
    border: 2px solid #2B2BD6;
    border-left-color: transparent;
    border-top-color: #2B2BD6;
    transform: rotate(45deg);
}

.navmenu-profile div#navbarScroll {
    min-width: 270px;
    padding: 0;
    width: 100%;
    overflow: initial;
    margin-top: 11px !important;
}

.navmenu-profile div#navbarScroll .navbar-nav {
    max-height: 85vh;
    overflow-y: auto;
}

.navmenu-profile div#navbarScroll .navbar-nav::-webkit-scrollbar {
    width: 0px;
}

header .navmenu-profile .navbar-collapse {
    display: none !important;
}

header .navmenu-profile .navbar-collapse.show {
    display: block !important;
}

header .navmenu-profile .navbar-collapse .navbar-nav {
    display: block;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link {
    background: #E9E9E9;
    border: 1px solid #fff;
    border-left: 0;
    border-right: 0;
    padding: 11px 30px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    transition: 0.4s;
    color: #2C251B;
}

header .navmenu-profile .navbar-nav a.nav-link img {
    max-width: 30px;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link:hover {
    background: #f2d1d3;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link.logout {
    background: #fff;
    padding: 20px 30px;
    color: #AF0612;
    text-transform: none;
    font-size: 18px;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link.activework {
    background: #AF0612;
    color: #fff;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link.activework a {
    color: #fff;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.nav-link.activework svg {
    height: 25px;
    width: 25px;
    transition: 0.4s;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.activework span {
    position: relative;
    transition: 0.3s;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.activework span::after {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    border: 1.5px solid #fff;
    position: absolute;
    top: 0%;
    left: 0;
    transform: rotate(45deg);
    transition: 0.3s;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.activework:hover span::after {
    display: none;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.activework:hover {
    background: #2C251B;
    border-radius: 0px 45px 45px 0px;
}

header .navmenu-profile .navbar-collapse .navbar-nav a.activework:hover svg {
    transform: scale(1.5) rotate(90deg);
}

.navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown {
    margin: 0;
    position: initial;
}

.navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown>a {
    background-image: url(../images/icons/artwork.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 90% center !important;
}

.navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu {
    left: 100% !important;
    top: 1px !important;
    padding-left: 1px !important;
    background: transparent;
}

.navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu:before {
    height: 65px;
    width: 3px;
    top: 0;
    left: 0px;
}

.navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu a.dropdown-item {
    background: #fff;
    border: 1px solid #E9E9E9;
    border-left: 0;
    border-right: 0;
    text-transform: none;
    min-height: 40px;
    font-size: 14px;
    font-weight: 400;
}

.navmenu-profile .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu a.dropdown-item:hover {
    background-color: #f2d1d3;
    font-weight: 500;
}

header .navmenu-profile .navbar-collapse .navbar-nav button.logout {
    width: 100%;
    border: 0;
    text-align: left;
    background: #fff !important;
    padding: 18px 30px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #AF0612 !important;
    font-weight: 600;
    font-size: 18px;
}

header .navmenu-profile .navbar-collapse .navbar-nav button.logout img {
    margin-left: 8px;
}

/* ----- paintings ----- */
.paintingbanner-img {
    position: relative;
    margin-bottom: 45px;
}

.paintingbanner-img:before {
    content: "";
    background: transparent linear-gradient(179deg, #FFFFFF00 40%, #2C251B 100%);
    mix-blend-mode: multiply;
    opacity: 0.75;
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
}

.painting-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.painting-content h3 {
    color: #fff;
    padding-left: 30px;
    padding-bottom: 20px;
}

.painting-content .category {
    background: #AF0612;
    color: #fff;
}

.painting-content .category {
    background: rgb(175 6 18 / 75%);
    color: #fff;
    padding: 20px 45px;
    border-radius: 50px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
}

.paintingbanner-img img {
    min-height: 250px;
    object-fit: cover;
}

/* ========== Profile page ============ */
.profile-banner {
    padding: 30px 0;
}

.profile-img {
    text-align: right;
    position: relative;
    margin-right: 15px;
}

.profile-img .user-icon img {
    border-radius: 100%;
    width: 155px !important;
    height: 155px !important;
    object-fit: cover;
    border: 2px solid #fff;
    max-width: initial;
}

.profile-img .user-icon {
    width: 167px;
    height: 167px;
    position: relative;
    margin-left: auto;
}

.profile-img .user-icon:before {
    border-radius: 100%;
    background: #BCBBBB;
    position: absolute;
    z-index: -1;
    width: 167px;
    height: 167px;
    content: "";
    left: 0;
    top: -6px;
}

.profile-img .user-icon:after {
    content: "";
    border: 5px solid #2B2BD6;
    border-left-color: transparent;
    border-radius: 50%;
    position: absolute;
    width: 167px;
    height: 167px;
    top: -6px;
    left: 0;
}

.profile-name {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.profile-name h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5px;
}

.profile-name .name h5 {
    font-weight: 400;
}

.profile-name .name {
    margin-right: 10px;
}

.profile-name img {
    height: 40px;
}

.profile-edit .btn {
    margin-right: 15px;
    margin-bottom: 10px;
}

.profile-edit .secondary-btn,
.profile-edit .secondary-btn:hover {
    background: #AF0612 !important;
    color: #fff !important;
}

.learnmore {
    color: #AF0612;
}

.btn.primary-btn.edit {
    border: 1px solid #2C251B !important;
    font-weight: 600;
    font-size: 14px;
    padding: 7px 15px;
    min-width: 155px;
    margin-right: 0;
}

.profile-content ul {
    margin-top: 5px;
}

.profile-content ul li {
    display: inline-block;
    padding-right: 35px;
    padding-bottom: 16px;
}

.profile-content ul li a,
.profile-content ul li button {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: 0 !important;
    border: 0 !important;
    min-width: auto;
    text-transform: none;
    font-weight: 400;
    color: #2C251B;
}

.profile-content ul li a:hover,
.profile-content ul li button:hover,
.profile-content ul li a:focus,
.profile-content ul li button:focus {
    color: #AF0612;
}

.profile-content ul li a span,
.profile-content ul li button span {
    font-weight: 600;
}

.profile-content h5.user_name {
    margin-bottom: 40px;
}

.profile-submit .primary-btn {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.26px;
    padding: 15px 20px;
    width: 100%;
    max-width: 370px;
    text-align: center;
}

.profile-artwork .artboard a {
    width: 100%;
}

.profile-tabs {
    border-top: 1px solid #AF0612;
}

.profile-tabs .nav-tabs {
    border: 0;
}

.profile-tabs .nav-tabs a {
    font-size: 16px;
    font-weight: 600;
    color: #2C251B;
    padding: 24px 0 0 0;
    border: 0;
    border-radius: 0;
    position: relative;
    margin-right: 50px;
    transition: 0.5S ease-in-out;
    text-transform: uppercase;
}

.profile-tabs .nav-tabs a.active {
    color: #AF0612;
    font-weight: bold;
    transition: 0.5S ease-in-out;
}

.profile-tabs .nav-tabs a.active:before {
    content: "";
    background: #AF0612;
    height: 10px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.sub-header.artwork-head h2 {
    color: #AF0612;
    font-size: 32px;
}

.painting-masonry.painting {
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 60px;
}

.painting-masonry.painting .loadmore-btn {
    padding-top: 30px;
}

.profile-about {
    margin: 30px auto;
}

.user-biography {
    margin-bottom: 40px;
}

.profile-about .about-user {
    padding-bottom: 50px;
}

.about-user h4,
.user-biography h4 {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #AF0612;
}

.profile-about .about-user p {
    font-weight: 500;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.bio-textarea {
    position: relative;
}

.bio-textarea span.count-text {
    position: absolute;
    right: 25px;
    bottom: 5px;
    font-weight: 500;
    font-size: 12px;
}

.bio-textarea textarea.form-control {
    padding-bottom: 25px;
}

.user-biography ul li span.count-text {
    position: absolute;
    right: 28px;
    bottom: 14px;
    font-size: 9px;
    font-weight: 600;
}

.user-biography ul li {
    margin-bottom: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    position: relative;
    padding-right: 70px;
}

.icons-exhibition {
    position: absolute;
    right: 0;
}

.user-biography ul li>span {
    padding-right: 25px;
    min-width: 25%;
    position: relative;
    max-width: 40%;
    word-break: break-all;
}

.user-biography ul li input,
.user-biography ul li select.form-control,
.user-biography ul li textarea {
    border: 1px solid #ced4da;
    padding: 10px 12px;
    height: auto;
    line-height: 1;
    border-radius: 4px;
    min-height: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    width: 100%;
    height: 39px;
    margin-bottom: 15px;
}

.add-exhib {
    margin-bottom: 15px;
}

.add-exhib button.btn {
    min-width: 90px;
    margin-right: 10px;
}

.user-biography ul li select.form-control {
    background-image: url(../images/arrow.svg);
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 12px;
    padding-right: 25px;
}

.user-biography ul li .rmdp-container {
    width: 100%;
}

.edit-bio {
    margin: 0 auto;
    max-width: 200px;
    display: block;
}

.user-btns {
    text-align: left;
    margin-top: 20px;
}

.user-btns button.btn {
    width: auto;
    display: inline-block;
    min-width: 100px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.icons-exhibition button {
    border: 0;
    padding: 0;
    background: transparent;
    font-size: 18px;
    color: #AF0612;
    margin-left: 10px;
}

.add-exhibit {
    min-width: auto;
    padding: 6px 17px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 20px;
}

.add-exhibit svg {
    position: relative;
    top: -2px;
    margin-left: 2px;
}

span.required {
    color: red;
    font-size: 11px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.user-biography textarea {
    height: 100%;
    overflow: hidden;
    border: 0;
    padding: 0;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
    resize: none;
}

.user-biography textarea::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background: #AF0612;
    border-radius: 20px;
}

.profile-tabs .tab-content {
    padding: 50px 0;
}

.profile-artwork {
    padding: 10px 0;
}

.profile-artwork .artboard {
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;
}

.profile-artwork .artboard::before {
    content: "";
    background: transparent linear-gradient(2deg, #2C251B 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.profile-artwork .artboard:hover::before {
    opacity: 1;
}

.profile-artwork .artboard img {
    width: 100%;
    height: 370px;
    object-fit: cover;
}

.profile-artwork .artboard h5 {
    position: absolute;
    bottom: 30px;
    z-index: 3;
    color: #fff;
    left: 30px;
    opacity: 0;
    transition: 0.2s;
}

.profile-artwork .artboard:hover h5 {
    opacity: 1;
}

.submit-artwork {
    float: right;
}

.submit-artwork .submit-work {
    background: #AF0612;
    color: #fff;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    height: 60px;
    line-height: 1.8;
    border: 0;
    transition: 0.5s;
    border-radius: 0;
}

.submit-artwork .submit-work:hover {
    background: #2C251B;
    border-radius: 0px 45px 45px 0px;
}

.submit-artwork .submit-work span {
    position: relative;
    margin-left: 25px;
    transition: 0.3s;
}

.submit-artwork .submit-work span::after {
    content: "";
    height: 25px;
    width: 25px;
    background: transparent;
    border: 1.5px solid #fff;
    position: absolute;
    top: -2px;
    left: 0;
    transform: rotate(45deg);
    transition: 0.3s;
}

.submit-artwork .submit-work:hover span::after {
    display: none;
}

.submit-artwork .submit-work span svg {
    height: 25px;
    width: 25px;
    transition: 0.4s;
}

.submit-artwork .submit-work:hover span svg {
    transform: scale(1.5) rotate(90deg);
}

/* profile modal */
.profile_popup .modal-content {
    background: #fff;
    backdrop-filter: blur(0);
    border-radius: 0;
    border: 0;
}

.Profile-modal {
    padding: 60px 70px;
}

.profile_popup button.closeicon {
    min-width: auto;
    padding: 0;
    background: #2C251B;
    color: #fff;
    border: 1px solid #2C251B;
    height: 36px;
    width: 36px;
    border-radius: 5px;
    font-size: 23px;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 16px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.profile_popup .modal-content {
    transition: all 0.3s ease-in-out;
}

.profile_popup .modal-content:hover button.closeicon {
    right: -36px;
    opacity: 1;
}

form.form-inline.search {
    margin-bottom: 50px;
}

form.form-inline.search button.btn-search svg {
    fill: #AF0612;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    top: -1px;
}

form.form-inline.search input.form-control {
    padding-left: 35px;
}

.profile-follower ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8ED;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.profile-follower ul li .modal-profile {
    display: flex;
    align-items: center;
}

.profilemodal-user img {
    width: 70px;
    height: 70px;
    min-width: 60px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
}

.profile-modaldetails h5 {
    margin-bottom: 3px;
}

.profile-modaldetails p {
    font-size: 12px;
}

.profile-follower ul li button.primary-btn.edit {
    min-width: 100px;
}

.primary-btn.undo {
    background-color: #2C251B !important;
    color: #fff !important;
}

.primary-btn.send {
    background: transparent !important;
    color: #2C251B !important;
}

.user-unfollow {
    margin-bottom: 30px;
}

.user-unfollow img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    object-fit: cover;
}

.unfollow-modal {
    background: rgb(44 37 27 / 30%);
}

.modal.show {
    z-index: 9999;
}

/* profile edit page design */
.profile-tabs.editprofile {
    max-width: 400px;
    margin: 25px auto;
}

.editprofile .nav-tabs a {
    margin: 0 !important;
    min-width: 170px;
    text-transform: uppercase;
}

.editprofile .nav-tabs {
    justify-content: space-between;
}

.editprofile .nav-tabs a:last-child {
    text-align: right;
}

.edit-page .user-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 15px;
}

.edit-page .user-detail .change-pic {
    background: transparent;
    padding: 0;
    min-width: auto;
    text-transform: none;
    color: #AF0612;
    font-size: 16px;
    font-weight: bold;
    border: 0;
    margin-top: 10px;
    margin-right: 0 !important;
    text-align: left;
}

.edit-page .follow-artist.row {
    margin-bottom: 10px;
}

.form-group label.form-label {
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #2C251B;
    padding-top: 0px;
}

.edit-page .form-group .form-control {
    border: 1px solid #2C251B99;
    border-radius: 0;
    padding: 5px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2C251B;
}

.edit-page .form-group {
    margin-bottom: 30px;
}

.edit-page .form-group small.text-mute {
    font-size: 12px;
    color: #2C251B;
}

.edit-page .form-group .form-check label {
    font-weight: 600;
    margin-left: 8px;
    color: #2C251B;
}

.edit-page .form-check-input[type=radio] {
    width: 17px;
    height: 17px;
    border: 1px solid #2C251B;
    margin-top: -1px;
}

.edit-page .form-check.form-check-inline .valid-feedback {
    position: absolute;
}

.edit-page .form-check.form-check-inline {
    display: inline-flex;
    align-items: center;
}

.edit-page .form-check-input:checked {
    background-color: #fff;
    background-image: url(../images/dot.svg) !important;
    background-position: center;
    background-size: contain;
}

.editprofile Button {
    width: 100%;
}

.edit-page {
    padding-bottom: 50px;
}

.edit-page .form-group textarea {
    max-height: 185px;
    overflow-y: auto;
}

.edit-page .user-icon img {
    width: 60px !important;
    height: 60px !important;
}

/* developing css */
.otp-logins>div {
    justify-content: space-between;
    margin-bottom: 14px;
}

.otp-logins .otp-inputs {
    width: 100%;
}

.otp-logins .otp-inputs input {
    border: 1px solid #2C251B73;
    border-radius: 5px;
    height: 60px;
    width: 100% !important;
    padding: 15px !important;
    background: #fff !important;
    font-size: 25px;
    max-width: 45px;
}

/* artwork submit */
section.artwork-submit {
    padding: 25px 0;
}

.profile-tabs.submit-work .nav-tabs {
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-top: 1px dashed #AF0612;
}

.profile-tabs.submit-work {
    border: 0;
}

.profile-tabs.submit-work .nav-tabs a {
    margin: 0;
    text-transform: uppercase;
    min-width: 170px;
}

.profile-tabs.submit-work .nav-tabs a:last-child {
    text-align: right;
}

.profile-tabs.submit-work .tab-content {
    max-width: 770px;
    margin: 0 auto;
}

.submit-work .select-text {
    background: #AF0612;
    color: #fff;
    text-align: center;
    max-width: 570px;
    margin: 0 auto;
    padding: 13px;
    font-size: 16px;
    font-weight: 500;
}

.image-upload.blog-uploadclass {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.drag-drop {
    cursor: pointer;
}

.image-upload {
    border: 1px dashed #AF0612;
    text-align: center;
    padding: 30px;
}

.drag-drop h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.drag-drop p a {
    color: #AF0612;
    font-weight: 600;
}

.drag-drop img {
    max-width: 55px;
    margin-bottom: 15px;
}

.uploaded-img .upload {
    border: 1px dashed #AF0612;
    height: 230px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
}

.upload.btn-upload button {
    background: rgb(0 0 0 / 70%);
    border: 0;
    padding: 0;
    position: absolute;
    z-index: 9;
    right: 0;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 0 0 0 8px;
}

.upload.btn-upload button svg {
    font-weight: bold;
    color: #fff;
    position: relative;
    top: -1px;
    font-size: 20px;
}

.upload img {
    height: 230px;
    width: 100%;
    object-fit: cover;
}

.uploaded-img.row {
    margin-top: 30px;
    margin-bottom: 70px;
    justify-content: center;
}

.uploaded-img.row .col-md-4 {
    margin: 0 auto;
}

.next-btn button,
.next-btn a {
    width: 100%;
}

h6.submit_verify {
    font-size: 20px;
    color: #AF0612;
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 45px;
    line-height: 24px;
}

.remove-user h6 {
    font-size: 20px;
    color: #AF0612;
    line-height: 24px;
}

.remove-user {
    margin-bottom: 45px;
}

.image-verify {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.modal-content .modal-footer a {
    font-weight: 600;
}

.btn.primary-btn.upload {
    color: #2C251B;
    font-size: 16px;
    width: 100%;
}

.artwork-end {
    margin-bottom: 110px;
}

.artwork-end h6 {
    max-width: 328px;
    padding-bottom: 0;
}

.upload-later span {
    margin-top: 12px;
    display: block;
}

.uploadlater-modal .modal-footer {
    padding-top: 20px;
}

.upload-later .remove-user {
    margin-bottom: 70px;
}

section.category-wrapper {
    padding-top: 40px;
}

.border-bottom.row .col-md-4 a {
    width: 100%;
}

/* artwork submit */
.submitDetails .submit-details {
    max-width: 450px;
    margin: 0 auto;
}

.w100 {
    width: 100%;
}

.submit-details input.form-control {
    border: 1px solid #2C251B99;
    border-radius: 0;
    padding: 5px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2C251B;
}

.submit-details select.form-control {
    color: #2C251B;
    font-size: 16px;
    font-weight: 500;
    border-radius: 0;
    border: 0;
    padding: 0;
    background-image: url(../images/arrow.svg) !important;
    background-repeat: no-repeat;
    background-position: center left;
    width: max-content;
    padding: 0 25px;
}

.submit-details .react-datepicker-wrapper input[type="text"],
form.exhibition-form input[type="text"] {
    border: 0;
    background-image: url(../images/arrow.svg) !important;
    background-repeat: no-repeat;
    background-position: center left;
    cursor: pointer;
    padding-left: 25px;
    font-weight: 500;
    font-size: 16px;
}

.submit-details .form-group {
    margin-bottom: 45px;
}

.submit-details .dropdown button.dropdown-toggle {
    text-transform: none;
    font-size: 16px;
    padding-left: 30px !important;
    position: relative;
}

.submit-details .dropdown button.dropdown-toggle::before {
    content: "\f078";
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    color: #AF0612;
    left: 0;
    position: absolute;
    top: 1px;
}

.submit-details .dropdown.show button.dropdown-toggle::before {
    content: "\f077";
}

.submit-details .dropdown .dropdown-menu {
    padding: 20px 0;
}

.submit-details .dropdown .dropdown-menu a.dropdown-item {
    font-weight: 600;
    font-size: 14px;
    padding: 8px 20px;
}

.submit-details .form-check input.form-check-input {
    border: 1px solid #AF0612;
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-right: 15px;
}

.submit-details .form-check input.form-check-input:checked {
    background-color: #AF0612 !important;
    background-image: url(../images/check.svg) !important;
}

.submit-details .form-check input.form-check-input:checked+label {
    font-weight: 600;
}

.checkoption.form-group {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.caption.form-group input,
.caption.form-group textarea {
    border: 0;
    border-bottom: 1px solid #2C251B99;
    font-weight: 400;
    padding: 0 0 19px;
    border-radius: 0;
}

.caption.form-group {
    margin-bottom: 90px;
}

section.artwork-submitdetails {
    padding: 50px 0 90px;
}

.image-checkbox {
    max-width: 790px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.radio-img .submitimg {
    position: relative;
}

.image-checkbox label.radio-img {
    padding: 0 15px;
}

.radio-img .submitimg img {
    width: 230px;
    height: 230px;
    background-color: #f2d1d3;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid #AF0612;
    position: relative;
    z-index: 2;
}

.radio-img input {
    display: none;
}

.radio-img input:checked+.submitimg::before {
    content: "";
    background-color: #f2d1d3;
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: -15px;
    left: -15px;
    z-index: -1;
}

.success svg {
    background: #AF0612;
    color: #fff;
    width: 62px;
    height: 62px;
    padding: 10px 18px;
    border-radius: 100%;
    line-height: 1;
    margin-bottom: 40px;
}

.success h3 {
    font-size: 26px;
    color: #AF0612;
    margin: 0 0 22px 0;
}

.success h6.submit_verify {
    max-width: 330px;
    margin: 0 auto;
    padding: 0;
}

.success-modal .modal-body {
    padding: 80px 70px;
}

.checkoption .height-width {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-left: 10px;
    margin-top: 5px;
    opacity: 0;
    height: 0;
}

.checkoption .height-width input.form-control {
    border: 0;
    width: 40px;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    min-width: 40px;
    padding: 5px 0;
    margin-right: 25px;
    -moz-appearance: textfield;
    appearance: textfield;
}

.checkoption .height-width input::-webkit-outer-spin-button,
.checkoption .height-width input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input#size:checked~.height-width {
    opacity: 1;
    height: auto;
}

input#size {
    height: 16px;
    width: 16px !important;
    border-radius: 100%;
    padding: 0;
}

input#size:checked~.height-width {
    opacity: 1;
    height: auto;
}

input#priceCheck {
    height: 16px;
    width: 16px !important;
    border-radius: 100%;
    padding: 0;
}

input#priceCheck:checked~.height-width {
    opacity: 1;
    height: auto;
}

input#priceCheck {
    height: 16px;
    width: 16px !important;
    border-radius: 100%;
    padding: 0;
}

input#priceCheck:checked~.height-width {
    opacity: 1;
    height: auto;
}

/* artwork detail */
.loadmore-btn {
    text-align: center;
}

.loadmore-btn button.load-more {
    padding: 12px 20px;
}

.artworkDetail {
    max-width: 770px;
    margin: 0 auto;
    padding: 25px 0 100px;
}

.detail-img img {
    width: auto;
    min-height: 60vh;
    max-height: 60vh;
    object-fit: contain;
}

.userartworkDetail .follow-artist .user-detail {
    margin-right: 15px;
}

.userartworkDetail .follow-artist img.followimg {
    margin-right: 0;
}

.detail-img {
    width: 100%;
    box-shadow: 0px 5px 15px #00000029;
    margin-bottom: 45px;
    text-align: center;
    position: relative;
}

.detail-desc h4 {
    font-size: 22px;
    font-weight: 600;
    color: #AF0612;
    margin-bottom: 15px;
}

.detail-desc .titleyear {
    border-top: 1px solid #AF0612;
    padding-top: 20px;
    margin-top: 20px;
}

.detail-desc h6 {
    font-weight: 600;
    font-size: 15px;
}

.year-creation {
    margin-bottom: 20px;
}

.profile-submit {
    margin: 5px 0 10px;
}

.height-width select.form-control {
    font-size: 12px;
    padding: 0 11px 0 0;
    background-size: 8px;
    background-position: center right;
}

.textRight {
    text-align: right;
}

.detail-desc strong {
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
}

.mb45 {
    margin-bottom: 40px;
}

.detail-desc button.secondary-btn {
    min-width: 115px;
    padding: 13px;
    letter-spacing: 0.2px;
    background: #2c251b;
    font-weight: bold;
    color: #fff;
}

.report {
    color: #AF0612 !important;
    font-weight: 600;
    background: transparent !important;
    min-width: auto;
    padding: 0;
    border: 0 !important;
    font-size: 14px;
    text-transform: none;
}

.report svg {
    font-size: 22px;
    margin-right: 5px;
    position: relative;
    top: -3px;
}

.artwork_single {
    position: relative;
    padding-top: 40px;
}

.share ul li {
    display: block;
    text-align: center;
    margin-bottom: 36px;
    position: relative;
}

.share ul li button {
    background: #71757A;
    color: #fff;
    font-size: 15px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    text-align: center;
    line-height: 13px;
    transition: 0.3s;
    min-width: auto;
    border: 0;
    padding: 0;
}

.share ul li button:hover {
    background: #AF0612;
}

.share ul li button:focus {
    background: #71757A;
}

.share ul li span {
    display: block;
    font-size: 13px;
    position: absolute;
    width: 100%;
    cursor: pointer;
    font-weight: 500;
}

.share ul li a .replyto {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.artwork_single .share {
    position: absolute;
    top: 55px;
    right: 0;
}

.artworkDetail .follow-text {
    opacity: 1;
}

a.globe-img {
    margin-right: 25px;
}

.artwork_single .share ul li button {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 13px;
    min-width: auto;
    border: 0;
    padding: 0;
}

.post-slider .slider-content {
    position: relative;
    padding: 18px;
}

.post-slider button.slick-arrow {
    top: 35%;
}

section.artwork-details button.slick-next {
    right: 0;
}

section.artwork-details button.slick-prev {
    left: 0;
}

.edit-page .form-group select.form-control {
    background-image: url(../images/arrow.svg) !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 10px) center !important;
    background-size: 12px;
}

.loader {
    background: rgb(0 0 0 / 35%);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}

.loader span.sr-only {
    opacity: 0;
}

.loader .spinner-border {
    width: 50px;
    height: 50px;
    position: absolute;
    border: 5px solid #AF0612;
    border-right-color: transparent;
}

.cropper-img .reactEasyCrop_Container {
    min-height: 300px;
    position: relative;
}

.cropper-img {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
}

.cropper-btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
}

.cropper-btns button.secondary-btn {
    min-width: 150px;
}

button.kep-login-facebook.metro {
    width: 100%;
    text-transform: none;
    font-size: 15px;
    background: #3B5998;
    padding: 12px 15px;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.kep-login-facebook.metro i {
    font-size: 25px;
    margin-right: 15px;
}

.language-select a.dropdown-item {
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    color: #2C251B;
}

.language-select a.dropdown-item option {
    font-weight: 600;
    font-size: 14px;
    color: #2C251B;
    display: contents;
}

.container.paintings .breadcrumb {
    position: relative;
    margin: 15px 0 30px;
    min-height: 10px;
}

.uploadmodal .modal-content {
    background: #fff;
}

.primary-btn a {
    color: #2C251B;
    font-size: 16px;
    transition: 0.5s;
}

.primary-btn:hover a {
    color: #fff;
}

.cropper-img .ReactCrop {
    width: 100%;
}

.cropper-img .ReactCrop__image {
    width: 100%;
}

.splashvideo,
.splash {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.splash img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.videoscreen {
    position: relative;
    clear: both;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0;
    z-index: 1;
    height: 100vh;
}

.fullwidth-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    font-weight: normal;
    overflow: hidden;
}

.wrapper-fullwidth-video {
    height: 100vh;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
}

.wrapper-fullwidth-video video {
    margin: 0px auto;
    background-color: rgb(233, 233, 233);
    padding: 0px;
    position: inherit;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
}

.profilemodal.modal .modal-content {
    background: #fff;
}

.Toastify {
    position: absolute;
    z-index: 99999;
}

.submitDetails {
    padding-bottom: 60px;
}

.technique-banner {
    text-align: center;
    padding: 30px 0 40px;
}

.technique-banner a {
    color: #AF0612;
}

.technique-banner h3 {
    margin-bottom: 10px;
}

.artwork-buttons {
    position: absolute;
    width: 107%;
    display: flex;
    justify-content: space-between;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-width: 850px;
}

.artwork-buttons button {
    background: transparent !important;
    border: 0;
    padding: 0;
    min-width: auto;
}

.artwork-buttons button img {
    min-height: auto;
}

.artwork-buttons .prev {
    transform: rotate(90deg);
}

.artwork-buttons .next {
    transform: rotate(-90deg);
}

.masonrypaint-content .content .card .card-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.slider-content {
    position: relative;
}

.slider-content .dropdown {
    background: #2C251B !important;
    padding: 6px !important;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s;
    z-index: 9;
}

.slider-content .dropdown:hover {
    background: #af0612 !important;
}

.slider-content .dropdown button {
    line-height: 0;
    color: #fff !important;
    font-size: 32px;
}

.show-menuwork {
    position: absolute;
    background: #fff;
    right: 0;
    transition: all 0.3s;
    z-index: 9;
    padding: 0;
    border: 1px solid #e9e9e9;
    min-width: 200px;
    max-height: 600px;
    overflow-y: auto;
    margin-top: 6px;
    opacity: 0;
    display: none;
}

.slider-content .dropdown:hover>.show-menuwork {
    opacity: 1;
    display: block;
}

.show-menuwork .dropdown-item {
    background: #fff;
    border: 1px solid #e9e9e9;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 13px 15px;
    color: #2c251b !important;
}

.show-menuwork .dropdown-item button {
    font-size: 14px;
    color: #2c251b !important;
    text-transform: inherit;
    border: 0;
    line-height: 1;
}

.show-menuwork .dropdown-item:hover {
    background-color: #f2d1d3;
    font-weight: 500;
    color: #2c251b !important;
}

.show-menuwork .dropdown-item:hover button {
    color: #2c251b !important;
    font-weight: 500;
}

.slider-content .dropdown .dropdown-menu,
.message-delete .dropdown-menu {
    padding: 0;
    transform: translate(-158px, 46px) !important;
    border: 1px solid #E9E9E9;
}

.slider-content .dropdown .dropdown-menu a,
.message-delete .dropdown-menu a {
    background: #fff;
    border: 1px solid #E9E9E9;
    text-transform: none;
    min-height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 13px 15px;
    color: #2C251B;
}

.slider-content .dropdown .dropdown-menu a button,
.message-delete .dropdown-menu button {
    background: #fff;
    border: 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 0;
    color: #2C251B !important;
}

.slider-content .dropdown .dropdown-menu a:hover,
.slider-content .dropdown .dropdown-menu a:focus,
.slider-content .dropdown .dropdown-menu a:hover button,
.message-delete .dropdown-menu a:hover {
    background-color: #f2d1d3;
    font-weight: 500;
    color: #2C251B;
}

.delete-artwork h6.submit_verify {
    padding-bottom: 15px;
}

.delete-artwork p {
    margin-bottom: 50px;
    font-size: 15px;
    line-height: 1.4;
}

.edit-artworkimg {
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;
}

.edit-artworkimg img {
    width: 100%;
}

button.comment-button.btn.btn-primary {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 1;
    background: #71757A;
    color: #fff;
    border: 0;
    display: inline-block;
    min-width: auto;
    border-radius: 100%;
    text-align: center;
    padding: 0;
}

.commentmodal .modal-content {
    background: #fff;
    border-radius: 0;
}

.commentmodal .modal-content .modal-body {
    padding: 50px 0;
}

.comment-modal h2,
.like-head {
    font-size: 24px;
    font-weight: bold;
    color: #AF0612;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-bottom: 3px solid #AF0612;
    padding-bottom: 10px;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
}

.comment-modal .user-detail {
    text-align: left;
    margin-right: 20px;
    width: 100%;
}

.comment-modal .user-detail h6 {
    margin-bottom: 3px;
    font-size: 16px;
    color: #2C251B;
}

.comment-modal .user-detail p {
    color: #2C251B;
    font-size: 14px;
}

.comment-desc {
    text-align: left;
    padding: 15px;
}

.comment-desc h4 {
    color: #AF0612;
    font-weight: 600;
    margin-bottom: 6px;
}

.comment-desc h6 {
    margin-bottom: 5px;
    font-size: 12px;
}

.comment-desc p {
    font-size: 12px;
}

.widthcomment {
    padding: 10px 70px;
}

.user-comment {
    margin-top: 25px;
    max-height: 310px;
    overflow-y: auto;
}

.comment-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8ED;
    margin: 25px 70px;
    padding-bottom: 20px;
}

.user-list {
    display: flex;
    align-items: flex-start;
    width: 80%;
}

.user-list img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 15px;
    min-width: 60px;
    object-fit: cover;
}

.user-list .user-detail p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 1; */
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
    word-break: break-all;
}

.user-list .user-detail h6 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.user-list .user-detail h6:hover {
    color: #AF0612;
}

.user-list .user-detail .like-comment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* max-width: 200px; */
}

.user-list .user-detail .like-comment a {
    font-size: 12px;
    font-weight: 600;
}

.like-comment button {
    font-size: 12px;
    font-weight: 600;
    color: #AF0612 !important;
    min-width: auto;
    text-transform: inherit;
    background: transparent !important;
    padding: 0;
    border: 0;
    width: auto;
    line-height: 1.2;
}

.comment-likes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
}

.comment-likes .select {
    font-size: 30px;
    color: #71757A !important;
    background: transparent;
    padding: 0;
    border: 0;
    min-width: auto;
    text-align: left;
}

.comment-likes .show.dropdown button.select {
    color: #AF0612 !important;
}

.comment-list .like {
    background: #71757A;
    width: 32px;
    height: 32px;
    line-height: 25px;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    min-width: 32px;
    font-size: 20px;
    padding: 0;
    border: 0;
}

.comment-likes.modal-cm .like {
    line-height: 16px;
}

.comment-list .dropdown-menu {
    min-width: auto;
    padding: 5px 20px;
}

.comment-list .dropdown-menu a.dropdown-item {
    padding: 0;
    margin: 10px 0;
}

.comment-list .dropdown-menu a.dropdown-item button {
    color: #AF0612 !important;
    text-transform: capitalize;
    font-weight: 500;
    background: transparent !important;
    text-align: left;
}

.delete-commentmodal .modal-content h6.submit_verify {
    max-width: 100%;
    margin-bottom: 50px;
}

.delete-commentmodal .modal-content span.or {
    margin-top: 15px;
    display: block;
}

.delete-commentmodal .modal-content .modal-footer {
    padding-top: 15px;
}

.report-commentmodal .modal-body {
    padding: 70px;
}

.report-modal h5 {
    font-size: 24px;
    color: #AF0612;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-bottom: 3px solid #AF0612;
    padding-bottom: 28px;
    margin-bottom: 40px;
    text-align: center;
}

.report-modal {
    text-align: left;
}

.report-modal h6 {
    color: #AF0612;
    font-size: 20px;
    margin-bottom: 25px;
}

.report-modal ul li {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid rgb(44 37 27 / 30%);
    padding: 13px 0;
    color: #2C251B;
    position: relative;
    padding-right: 30px;
}

.report-modal ul li svg {
    font-weight: 900;
    color: rgb(44 37 27 / 30%);
    position: absolute;
    right: 5px;
    font-size: 16px;
}

.report-form input {
    width: 100%;
    border: 1px solid rgb(44 37 27 / 30%);
    padding: 12px 15px;
    font-size: 16px;
    background: transparent;
}

.report-form button.btn.primary-btn {
    font-size: 13px;
    padding: 10px 15px;
    min-width: 100px;
    max-width: 140px;
}

.report-form {
    display: flex;
    align-items: flex-end;
}

/* .report-modal ul li:after {
    content: "\f054";
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900;
    color: rgb(44 37 27 / 30%);
    position: absolute;
    right: 10px;
    font-size: 16px;
} */
.closebutton {
    position: absolute;
    right: -35px;
    top: 0;
    color: #fff !important;
    width: 35px !important;
    background: #2C251B !important;
    text-align: center;
    font-size: 20px;
    height: 35px;
    line-height: 14px;
    border-radius: 5px;
    transition: 0.3s;
    border: 0;
    min-width: auto;
    padding: 0;
}

.comment-modal.replymodal .user-comment {
    margin: 0;
}

.reply-modal .modal-body {
    padding: 50px 0;
}

form.comment-form input {
    border: 0;
    border-radius: 0;
    background: #EEEEEE !important;
    font-size: 14px;
    color: #2C251B;
    padding: 35px 100px 35px 100px;
    position: relative;
}

form.comment-form {
    position: relative;
    margin-bottom: 15px;
}

form.comment-form button {
    border: 0 !important;
    background: transparent !important;
    padding: 0;
    width: auto;
    min-width: auto;
    color: #AF0612 !important;
    font-size: 35px;
    position: absolute;
    right: 60px;
    top: 28px;
    outline: none !important;
}

.user-comment::-webkit-scrollbar {
    width: 0px;
}

.viewWrapSayHi .first-chats {
    max-width: 220px;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
}

span.chat-number {
    background: #2C251B;
    color: #fff;
    padding: 4px 13px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 4px;
    min-width: 34px;
    position: absolute;
    height: 34px;
    text-align: center;
    line-height: 1;
    top: -35px;
    right: 0;
}

.request-number {
    background: #2C251B;
    color: #fff;
    padding: 4px 13px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 4px;
    min-width: 34px;
    position: absolute;
    height: 34px;
    text-align: center;
    line-height: 1;
    top: 0;
    right: 30px;
}

.comment-list .accordion {
    max-width: 85%;
    margin-left: auto;
    padding-left: 10px;
    margin-top: 20px;
    width: 100%;
}

.comment-list .accordion .card-header {
    padding: 0;
    border-radius: 0;
    border: 0;
    background: transparent;
    text-align: left;
    cursor: pointer;
}

.comment-list .accordion .card-header h5 {
    font-size: 13px;
    color: #AF0612;
    padding-left: 45px;
    position: relative;
}

.comment-list .accordion .card-header h5::before {
    content: "";
    background: #AF0612;
    height: 2px;
    width: 40px;
    position: absolute;
    left: 0;
    top: 8px;
}

.comment-list .accordion .card {
    border-radius: 0;
    border: 0;
}

.comment-list .accordion .comment-list {
    margin: 0;
}

.comment-list .accordion .card-body {
    padding: 1rem 0 0 0;
}

.comment-list .accordion .card-body .comment-list {
    border: 0;
}

.search-tab {
    display: flex;
    border-bottom: 1px solid #2C251B !important;
    /* width: 66%; */
    justify-content: flex-end;
    position: relative;
    z-index: 999;
}

.search-tab form.form-inline input {
    border: 0 !important;
    min-width: 140px;
}

.search-tab form.form-inline {
    width: 100%;
}

.search-tab nav.navbar-nav.nav-tabs {
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    max-width: 250px;
}

.search-tab nav.navbar-nav.nav-tabs a {
    color: #2C251B;
    font-size: 14px;
    padding: 10px 22px 5px;
    border: 0;
    letter-spacing: 0.4px;
    width: max-content;
}

.search-tab nav.navbar-nav.nav-tabs a.active {
    color: #AF0612;
    font-weight: bold;
    border-bottom: 6px solid #AF0612;
}

.profile-follower.like-modals {
    margin-top: 40px;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 20px;
}

.border-label,
.label-exbit,
.label-yellow,
.label-both {
    border: 2px solid #AF0612;
    color: #AF0612;
    background: #FFFFFF;
    padding: 8px 15px;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.hover-labeldata {
    position: absolute;
    background: rgb(242 241 239 / 85%);
    width: 100%;
    padding: 25px 25px 35px;
    display: none;
}

.label-hover:hover .hover-labeldata {
    display: block;
}

.hover-labeldata p {
    margin-bottom: 10px;
    font-weight: 400;
}

.hover-labeldata h6 {
    font-weight: 600;
    font-size: 16px;
}

.active-premium .active-body h5 p {
    padding: 0;
}

form.exhibition-form {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
}

form.exhibition-form label.form-label {
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
}

form.exhibition-form .react-datepicker-wrapper {
    width: auto;
    padding-left: 15px;
}

form.exhibition-form .form-group.row {
    justify-content: center;
}

form.exhibition-form .react-datepicker-wrapper input {
    max-width: 100px;
    text-align: center;
}

.exhibition-accordian .card {
    border-radius: 0;
    border: 0;
    margin-bottom: 45px;
    border-bottom: 2px solid #2C251B;
}

.exhibition-accordian .card .card-header {
    background: transparent;
    border-radius: 0;
    border: 2px solid #AF0612;
    padding: 36px 20px;
    text-align: center;
    cursor: pointer;
}

.exhibition-accordian .card .card-header h5 {
    font-size: 24px;
    color: #AF0612;
}

.exhibition-accordian .card .card-body {
    padding: 25px 0;
}

.exhibition-accordian .card .card-body .border-bottom.row {
    border: 0 !important;
    margin-bottom: 0;
    padding: 0;
}

.exhibition-accordian .card .collapse.show {
    border-bottom: 1px solid #2C251B;
}

.lightbox-post {
    max-width: 770px;
    margin: 0 auto;
}

.lightbox-post .lightimg a,
.lightbox-post .lightimg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.lightbox-post .lightimg {
    margin-bottom: 25px;
    display: flex;
}

.message-user.follow-artist .edit {
    margin-right: 15px;
}

.message-user.follow-artist {
    margin-bottom: 10px;
}

.message-user.follow-artist .follow-text {
    margin-right: 35px;
}

.message-delete .dropdown button.dropdown-toggle {
    line-height: 1;
    color: #af0612 !important;
    font-size: 27px;
    transform: rotate(90deg);
    text-align: center;
}

.reply-user {
    float: right;
    background: #71757A;
    color: #fff !important;
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 31px;
    border-radius: 100%;
    text-align: center;
    margin-top: 20px;
}

.reply-user .replyto {
    transform: scaleX(-1);
}

img.block-user {
    border-radius: 100pc;
    margin-bottom: 40px;
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.exhibition-point {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 0 40px;
}

.exhibition-point h5 {
    margin-bottom: 40px;
}

.exhibition-point ul li {
    margin-bottom: 25px;
}

.point-text {
    display: flex;
    align-items: center;
}

.point-text img {
    margin-right: 15px;
    max-width: 35px;
}

.exhibition-text h6 {
    font-weight: 600;
}

.exhibition-text p {
    font-weight: 600;
    color: #AF0612;
    margin-bottom: 9px;
}

.check-artwork {
    display: flex;
    height: 100%;
    position: relative;
    padding-bottom: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.check-artwork input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 25px;
    width: 25px;
    border: 2px solid #AF0612;
    border-radius: 100%;
    background-color: #fff;
}

.check-artwork input:checked~.checkmark {
    background-color: #AF0612;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-artwork input:checked~.checkmark:after {
    display: block;
}

.check-artwork .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

label.check-artwork img {
    max-height: 370px;
    width: 100%;
    object-fit: cover;
}

.artworkexhibition-head {
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.artworkexhibition-body .row {
    margin-bottom: 50px;
    justify-content: center;
}

.ArtworkExhibition {
    padding: 20px 0 60px;
}

.exhibition-next {
    text-align: center;
}

.exhibition-next .primary-btn {
    display: block;
    padding: 14px 30px;
    font-size: 16px;
    max-width: 430px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 auto;
}

.artworkexhibition-head h5 {
    line-height: 1.3;
}

.exhibition-detail {
    margin-bottom: 50px;
}

.exhibition-detail img {
    margin-bottom: 30px;
    max-height: 370px;
    min-height: 370px;
    width: 100%;
    object-fit: cover;
}

.exhibition-detail h5 {
    color: #AF0612;
    font-weight: bold;
    margin-bottom: 7px;
}

.exhibition-detail span.category {
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.exhibition-detail h6 {
    font-size: 12px;
    font-weight: 600;
}

.exhibition-detail h6.availability {
    color: #71757A;
}

.availability.disablity {
    opacity: 0.5;
    pointer-events: none;
}

.exhibition-detail p {
    font-size: 12px;
    line-height: 1.3;
    padding: 12px 0 30px;
    max-width: 325px;
}

.exhibition-detail .btn {
    border: 0;
    border-top: 5px solid #2C251B;
    display: block;
    background: transparent;
    font-size: 16px;
    text-transform: capitalize;
    text-align: right;
    padding: 10px 0 15px;
    width: 100%;
}

.exhibition-detail .btn:hover,
.exhibition-detail .btn:focus {
    background-color: #2C251B;
    color: #fff;
    padding: 10px 20px 15px;
}

.galleryEx {
    max-width: 570px;
    border-bottom: 1px solid #2C251B;
    padding-bottom: 40px;
    margin: 40px auto;
    display: flex;
}

.galleryEx-img img {
    box-shadow: 0 5px 10px rgb(156 172 185 / 45%);
    border-radius: 9px;
    max-height: 200px;
    min-height: 200px;
    width: 100%;
    object-fit: cover;
}

.galleryEx-img {
    margin-right: 30px;
    min-width: 45%;
}

.galleryEx-content p {
    font-size: 14px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.galleryEx-content span.points {
    display: block;
    font-size: 12px;
    color: #2C251B;
    margin-bottom: 20px;
}

.galleryEx-content span.exhibition-period {
    display: block;
    font-size: 12px;
    color: #2C251B;
    margin-bottom: 5px;
}

.galleryEx-content h6 {
    color: #AF0612;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 40px;
}

.basel-gallery {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.basel-gallery h6 {
    color: #2C251B;
    margin: 0;
    padding-right: 10px;
}

.basel-gallery .btn.primary-btn {
    border: 1px solid #2C251B;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    padding: 7px;
    min-width: 100px;
    text-align: center;
    max-width: 120px;
}

.basel-gallery .btn.primary-btn:hover,
.basel-gallery .btn.primary-btn:focus {
    border: 1px solid #2C251B !important;
}

.basel-gallery .btn.primary-btn.booked {
    background: #AF0612;
    color: #fff;
    border-color: #AF0612;
}

.exhibition-gallery .galleryEx:last-child {
    border: 0;
}

.booked-outmodal .modal-body {
    padding: 100px 70px;
}

.exhibition-next p {
    max-width: 430px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 600;
    color: #AF0612;
    margin-bottom: 15px;
}

section.ArtworkExhibition-detail .artworkexhibition-body .row {
    margin-bottom: 0;
    justify-content: center;
}

section.ArtworkExhibition-detail {
    padding-bottom: 80px;
}

.exhibition-detail .btn.btn-primary.basel {
    background: #2C251B;
    color: #fff;
    padding: 10px 20px 15px;
}

/* setting page css */
.setting-breadcrumb ul li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    padding-right: 20px;
    font-weight: 600;
}

.setting-breadcrumb ul li:first-child {
    font-weight: 500;
}

.setting-breadcrumb ul li::after {
    content: "/";
    position: relative;
    right: -10px;
    top: 1px;
}

.setting-breadcrumb ul li:last-child::after {
    display: none;
}

.setting-breadcrumb {
    border-bottom: 1px solid #2C251B;
    padding: 0 25px 10px;
    margin-bottom: 10px;
}

.flex-column.setting .nav-item {
    background: #EEEEEE;
    margin-bottom: 3px;
}

.flex-column.setting .nav-item a {
    border-radius: 0;
    color: #2C251B;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 35px;
    position: relative;
}

.flex-column.setting .nav-item a.active {
    background: #f2d1d3;
    font-weight: 700;
}

span.premium {
    background: #AF0612;
    color: #fff;
    border-radius: 0 0 0 15px;
    padding: 2px 10px 4px 15px;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
}

.setting-accordian .card {
    border-radius: 0;
    background: #fff;
    border: 0;
    margin-bottom: 5px;
}

.widthdata {
    max-width: 500px;
    margin: 0 auto;
    padding: 50px 0;
}

.setting-accordian .card-header {
    border-radius: 0;
    background: #eee;
    border-bottom: 1px solid #2C251B;
    font-size: 18px;
    padding: 18px 30px;
    font-weight: 600;
    cursor: pointer;
}

.setting-accordian .card-body {
    padding: 25px 0;
}

.setting-tab textarea {
    border-radius: 0;
    border: 1px solid #707070;
}

.setting-accordian .card-body button.btn {
    width: 100%;
}

.setting-accordian .card-body input[type="file"] {
    background: #AF0612;
    color: #fff;
    padding: 8px 12px;
    width: 100%;
    font-size: 12px;
    white-space: break-spaces;
}

.setting-accordian .card-body input[type="file"]::-webkit-file-upload-button {
    background: #AF0612;
    color: #fff;
    padding: 3px;
    border: 0;
}

.setting-accordian .card-body input.form-control-file::before {
    content: 'Add Image or drop Image here';
    padding-right: 25px;
    background-image: url(../images/fileicon.png);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center right;
    margin-right: 15px;
    font-weight: 500;
    font-size: 14px;
    background-color: #AF0612;
}

.react-datepicker span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous,
.react-datepicker span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
    width: 0;
}

/* cms header css */
.text-right {
    text-align: right;
}

.text-left.menu,
.text-left.menu img {
    text-align: left;
    margin: 0;
}

header.CMS-header {
    padding: 30px 0 13px;
}

.cmsheader-row.row {
    align-items: center;
}

ul.cms-rightmenu li {
    display: inline-block;
    padding-left: 30px;
}

ul.cms-rightmenu li a {
    font-weight: 600;
    font-size: 14px;
}

ul.cms-rightmenu li.artboxy a {
    color: #AF0612;
}

header.CMS-header .setting-breadcrumb {
    border: 0;
    margin-top: 20px;
    padding: 0;
}

.helpcenter-banner {
    position: relative;
    padding: 20px 0 80px;
}

.helpcenter-banner:before {
    content: "";
    background: #EEEEEE;
    width: 100%;
    height: 45%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.left-block ul li {
    color: rgb(85 85 85 / 75%);
    font-size: 20px;
    font-weight: 600;
    padding: 14px 0;
}

.left-block ul li.active {
    color: #AF0612;
}

.left-block {
    border-right: 1px solid #2C251B;
    height: 100%;
}

.right-block p {
    font-size: 14px;
    margin-bottom: 40px;
}

.right-block h2 {
    font-weight: 500;
    margin-bottom: 10px;
}

.right-block ul {
    list-style: disc;
    padding-left: 20px;
    padding-bottom: 20px;
}

.right-block ul li {
    margin-bottom: 20px;
}


.radiobtn input {
    opacity: 0;
    position: absolute;
}

.radiobtn input,
.radio-custom-label {
    display: block;
    cursor: pointer;
    padding: 19px;
    background: #EEEEEE;
    margin-bottom: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.radio-custom-label {
    position: relative;
}

.radiobtn input:checked+.radio-custom-label {
    background: #AF0612;
    color: #fff;
}

.radiobtn input+.radio-custom-label:before {
    content: '';
    background: #fff;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-right: 0;
    text-align: center;
    position: absolute;
    right: 0;
    top: -12px;
}

.radiobtn input+.radio-custom-label:before {
    border-radius: 50%;
}

.radiobtn input:checked+.radio-custom-label:before {
    content: "";
    border: 1px solid #AF0612;
    width: 28px;
    height: 28px;
    color: #AF0612;
    font-size: 14px;
    line-height: 27px;
    box-shadow: 0 3px 6px rgb(172 26 39 / 75%);
    background-image: url(../images/check.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;

}

.radio-custom-label span.save {
    font-size: 14px;
    position: absolute;
    padding-left: 40px;
    top: 20px;
}

.already-buy {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.4;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 30px;
}

.already-buy p {
    margin-bottom: 30px;
}

.already-buy .restore {
    background: transparent;
    padding: 0 6px;
    border: 0;
    min-width: auto;
    text-transform: inherit;
    color: #AF0612;
    font-size: 16px;
    font-weight: bold;
}

.already-buy span {
    font-weight: 500;
}

.setting-page {
    padding-bottom: 60px;
}

/* 
.active-subscrip .active-header {
    background: #f2d1d3;
    padding: 20px 30px;
    border-bottom: 1px solid #2C251B;
}

.active-subscrip .active-header h4 {
    font-size: 18px;
    font-weight: 600;
}

.active-subscrip .active-body {
    background: #EEEEEE;
    padding: 22px 30px;
}

.active-subscrip .active-body h5 {
    color: #AF0612;
    font-weight: bold;
    margin: 5px 0 30px;
}

.active-subscrip .active-body h5 span {
    font-size: 14px;
    color: #2C251B;
    font-weight: 400;
}

.active-subscrip .active-body .btn.primary-btn {
    width: 100%;
}

.active-subscrip {
    padding: 30px 0;
} */

.setting-page .profile-follower ul {
    max-height: 100vh;
    overflow-y: auto;
    padding-right: 15px;
}

.setting-page .profile-follower ul::-webkit-scrollbar {
    width: 0;
}


form.form-inline .form-group {
    position: relative;
}

.share-profile form.form-inline .form-group input.form-control {
    border-top: 1px solid #71757A;
    border-bottom: 1px solid #71757A !important;
}

.share-profile form.form-inline {
    margin-bottom: 25px;
}

.share-profile form.form-inline .form-group button.btn-search {
    color: #AF0612;
    font-size: 26px;
}

.share-profile form.form-inline .form-group input.form-control {
    padding: 8px 40px;
    color: #71757A;
    font-size: 16px;
}

.share-profile .profile-follower ul li {
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.share-profile svg.ai-user {
    color: #E2E8ED;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 5px;
}

.w100 {
    width: 100%;
}

.archive .nav.nav-tabs {
    border-bottom: 2px solid #AF0612;
}

.archive .nav.nav-tabs a.nav-link {
    font-size: 16px;
    font-weight: 600;
    color: #2C251B;
    text-transform: uppercase;
    width: 50%;
    text-align: center;
    padding: 20px 12px;
    border: 0;
}

.archive .nav.nav-tabs a.nav-link.active {
    color: #AF0612;
    border-bottom: 10px solid #AF0612;
}

.archive {
    max-width: 600px;
    margin: 0 auto;
}

.art-archive img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 150px;
    min-height: 150px;
}

.aarchive-text ul button,
.aarchive-text ul a {
    background: rgb(255 255 255 / 70%) !important;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #2C251B !important;
    text-transform: initial;
    border: 0;
    text-align: left;
    min-width: auto;
    width: 100%;
    line-height: 1;
}

.aarchive-text ul button:hover,
.aarchive-text ul a:hover {
    background: rgb(244 220 173 / 80%) !important;
    color: #2C251B;
    font-weight: 500;
}

.aarchive-text ul li {
    margin: 2px;
}

.art-archive {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.aarchive-text {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    opacity: 0;
    transition: 0.2s linear;
}

.art-archive:hover .aarchive-text {
    opacity: 1;
}

.archive .tab-pane {
    padding-top: 25px;
}

.deleted {
    background: #2C251B;
    color: #fff;
    padding: 9px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.lang-change {
    box-shadow: 0 10px 15px rgb(44 37 27 / 15%);
    padding: 15px 0 40px 0;
    max-width: 370px;
}

.lang-change ul li {
    font-size: 14px;
    font-weight: 600;
    color: #2C251B;
    padding: 12px 20px;
    cursor: pointer;
    transition: all 0.4s ease;
}

.lang-change ul li:hover,
.lang-change ul li.active {
    background: #2C251B;
    color: #fff;
}

.lang-change ul li span {
    display: block;
    font-weight: 400;
}

.right-block h1 {
    margin-bottom: 40px;
    font-size: 60px;
}

.right-block h3 {
    font-size: 36px;
    color: #AF0612;
    margin-bottom: 20px;
    line-height: 1.2;
}

.right-block .accordion {
    margin-bottom: 40px;
}

.right-block .accordion .card-header {
    background: #2C251B;
    border-radius: 15px;
    color: #fff;
    padding: 20px 30px;
    cursor: pointer;
}

.right-block .accordion .card-header h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    position: relative;
}

.right-block .accordion .card-header h5::after {
    content: "\f078";
    position: absolute;
    right: 0;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 500;
}

.right-block .accordion .card {
    border: 0;
    margin-bottom: 15px;
    background: #f2d1d3;
    border-radius: 15px;
}

.right-block .accordion .card .collapse .card-body p {
    font-size: 16px;
    margin-bottom: 10px;
}

.right-block .accordion .card .collapse .card-body {
    padding: 15px 25px;
}

.main-wrapper.about .setting-breadcrumb {
    display: none;
}

.about-banner img {
    border-radius: 45px;
}

.about-banner {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}

.about-banner h2 {
    background: rgb(175 6 18 / 75%);
    color: #fff;
    font-size: 64px;
    font-weight: bold;
    padding: 0 6px 5px 12px;
    margin-bottom: 6px;
}

.about-banner .text {
    position: absolute;
    right: 0;
}

.about-center p {
    max-width: 570px;
}

.alinc {
    align-items: center;
}

.cont-img img {
    border-radius: 45px;
}

.cont-text h3 {
    color: #AF0612;
    margin-bottom: 50px;
}

.cont-text h2 {
    word-spacing: 4px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 25px;
}

.max-470 {
    max-width: 470px;
}

.third-about {
    padding: 60px 0;
}

.fourth-about {
    padding: 60px 0 100px;
    position: relative;
}

.fourth-about:before {
    content: "";
    background: #EEEEEE;
    width: 100%;
    height: 70%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.about-center {
    background: #EEEEEE;
    padding: 20px 0;
    position: relative;
}

.about-content {
    position: relative;
}

.about-content::before {
    content: "";
    background: #EEEEEE;
    width: 100%;
    height: 32%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.gallery-modal .modal-body {
    padding: 30px 30px;
    background: #fff;
}

.gallery-modal .modal-dialog {
    max-width: 700px !important;
    width: 100%;
}

.galleryEx-content {
    text-align: left;
    width: 100%;
}

.exhibition-gallery {
    max-height: 80vh;
    overflow-y: auto;
}

.gallery-modal .modal-dialog.modal-md {
    margin: 0 auto;
    min-height: 100%;
}

.exhibition-gallery::-webkit-scrollbar {
    display: none;
}

.gallery-collection {
    max-width: 750px;
    margin: 0 auto;
}

.gallery-collection .cont-text p {
    margin-bottom: 15px;
}

section.Gallery_partner {
    padding: 35px 0 80px;
}

section.Gallery_partner a {
    color: #AF0612 !important;
    font-weight: 500;
}

section.Gallery_partner p {
    margin-bottom: 15px;
}

.video-partner {
    margin-bottom: 20px;
}

.video-partner iframe {
    border-radius: 4px;
}

.uploaded-img.blog-uplimg.row .upload.btn-upload {
    height: 100%;
    max-height: 450px;
}

.uploaded-img.blog-uplimg.row .upload.btn-upload img {
    height: 100%;
}

.submitDetails.blog-submitdtl .radio-img .submitimg img {
    width: 100%;
    height: 100%;
}

.submitDetails.blog-submitdtl .radio-img .submitimg::before {
    display: none;
}

header.header {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
    top: 0;
}

.main-wrapper {
    padding-top: 190px;
}

.progressuser {
    padding: 0;
    width: 34px;
    background: #ddd;
    position: relative;
    height: 34px;
    border-radius: 100%;
    margin-right: 10px;
    overflow: hidden;
}

.bar img {
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 0;
    line-height: 30px;
    border-radius: 100%;
    border: 2px solid #fff;
    left: 2px;
    top: 2px;
    object-fit: cover;
    background-color: #ddd;
}

.bar {
    margin-left: auto;
    height: 100%;
}

.currentprogress {
    margin: 0 auto;
    padding: 0;
    width: 170px;
    background: #ddd;
    position: relative;
    height: 170px;
    border-radius: 100%;
    overflow: hidden;
}

.currentbar img {
    position: absolute;
    width: 165px;
    height: 165px;
    margin: 0;
    line-height: 165px;
    border-radius: 100%;
    border: 3px solid #fff;
    left: 2.45px;
    top: 2.3px;
    object-fit: cover;
    background-color: #ddd;
}

.currentbar {
    margin-left: auto;
    height: 100%;
}

.edituserprofile {
    margin: 0 auto;
    padding: 0;
    width: 79px;
    background: #ddd;
    position: relative;
    height: 79px;
    border-radius: 100%;
    overflow: hidden;
}

.ediruser img {
    position: absolute;
    width: 75px;
    height: 75px;
    margin: 0;
    line-height: 75px;
    border-radius: 100%;
    border: 3px solid #fff;
    left: 2px;
    top: 2px;
    object-fit: cover;
    background-color: #ddd;
}

.teat-userdetails {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.teat-userdetails .share-usertextarea {
    width: calc(100% - 85px);
}

.progressuser.workmodal-user {
    width: 70px;
    height: 70px;
}

.progressuser.workmodal-user .bar img {
    width: 66px;
    height: 66px;
}

.ediruser {
    margin-left: auto;
    height: 100%;
}

section.artist .loadmore-btn {
    padding: 0;
}

section.artist .painting-masonry .loadmore-btn {
    padding-top: 50px;
}

button.emoji-btn.btn.btn-primary {
    position: absolute;
    left: 65px;
    font-size: 25px;
    margin-top: 5px;
    width: 30px;
}

.first-chats {
    position: relative;
}

aside.emoji-picker-react {
    position: absolute !important;
    z-index: 999;
    top: 195px;
}

.headerChatBoard aside.emoji-picker-react {
    bottom: 60px;
    top: auto;
}

.trans-original {
    color: #AF0612;
    font-size: 10px !important;
    font-weight: 500;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
}

.trans-original svg {
    color: #000;
    font-size: 24px;
    padding-left: 5px;
}

.translated-text p {
    margin-top: 10px;
    transition: 0.3s;
}

.gallery-slick {
    background: #fafafa;
    padding: 15px;
    position: relative;
    transition: all 0.2s;
    height: 100%;
}

.marbnt {
    margin-bottom: 25px;
}

.gallery-slick:hover {
    background: #dad8d8;
}

.slick-partner-img img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    min-height: 400px;
}

.slick-partner-img {
    margin-bottom: 15px;
}

.gallery-slick button.slick-arrow.slick-prev {
    left: -9px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9;
    padding: 14px 23px;
    background-image: url(../images/arrow2.svg) !important;
}

.gallery-slick button.slick-arrow.slick-next {
    right: -9px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9;
    padding: 14px 23px;
    background-image: url(../images/arrow2.svg) !important;
}

.gallery-slick .data-partner h5 {
    color: #AF0612;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 17px;
}

.gallery-slick .data-partner h6 {
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 13px;
}

.opening-hours {
    margin-bottom: 20px;
}

.opening-hours p span {
    font-weight: 600;
}

.opening-hours.exhibition {
    border-top: 1px solid #AF0612;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.faq-data {
    padding-bottom: 50px;
}

.gallery-select .selectday {
    color: #2c251b !important;
    font-size: 15px;
    font-weight: 500;
    border-radius: 0;
    border: 0;
    background-image: url(../images/select.png) !important;
    background-repeat: no-repeat;
    background-size: 27px;
    background-position: center;
    background-position-x: calc(100% - 3px);
    width: max-content;
    padding: 6px 40px 7px 15px;
    background-color: #fff !important;
    cursor: pointer;
}

.gallery-select .selectday:focus {
    box-shadow: none;
}

.gallery-cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
}

.no-found {
    text-align: center;
    background: #fff;
    box-shadow: 0 3px 13px rgb(0 0 0 / 10%);
    padding: 30px 15px;
    font-weight: 600;
    display: block;
    width: 100%;
}

.userartworkDetail {
    max-width: 770px;
    margin: 0 auto;
}

.userartworkDetail .follow-head {
    margin: 0;
}

.painting-masonry>div {
    justify-content: center;
}

/* .monthly-slider {
    margin-bottom: 40px;
}

.subscrip-slider {
    text-align: center;
}

.subscrip-slider img {
    width: 100px;
    margin: 0 auto;
    margin-bottom: 25px;
    object-fit: cover;
    height: 100px;
}

.subscrip-slider h3 {
    color: #AF0612;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 1.1;
    letter-spacing: 0;
}

.subscrip-slider p {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0;
}

.monthly-slider ul.slick-dots li button {
    border: 1px solid #AF0612;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    text-align: center;
}

.monthly-slider ul.slick-dots li button::before {
    width: 16px;
    height: 15px;
    line-height: 17px;
    font-size: 11px;
    color: #AF0612;
    opacity: 0;
}

.monthly-slider ul.slick-dots li.slick-active button::before {
    opacity: 1;
}

.monthly-slider ul.slick-dots {
    bottom: 0;
    position: relative;
    padding-top: 6px;
} */

/* ------------------ chat page css start ----------------- */
.viewListUser {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.viewListUser button {
    border: 0;
    padding: 0;
    outline: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: transparent;
    padding: 8px 10px;
}

.viewListUser button:hover {
    background: #DEDEDE;
}

.viewListUser button.viewWrapItem.Focused,
.viewListUser button.viewWrapItemFocused {
    background: #2C251B;
    color: #fff;
}

.chat-tabs img.viewAvatarItem {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 100%;
    min-width: 55px;
}

.user-online {
    margin-right: 10px;
    position: relative;
}

.viewListUser form {
    width: 100%;
}

.viewListUser form button.btn-search {
    top: 26px;
}

.viewListUser form input.form-control.form-control {
    padding: 22px 8px 22px 25px;
    border-bottom: 1px solid rgb(44 37 27 / 30%) !important;
}

.chat-tabs {
    border: 0;
    padding-bottom: 20px;
}

.chat-tabs .nav-tabs {
    border-bottom: 1px solid #AF0612;
}

.chat-tabs .nav-tabs a {
    font-size: 16px;
    font-weight: 600;
    color: #2C251B;
    padding: 5px 40px 20px 40px;
    border: 0;
    border-radius: 0;
    position: relative;
    transition: 0.5S ease-in-out;
    border: 0 !important;
}

.chat-tabs .nav-tabs a.active {
    color: #AF0612;
    font-weight: bold;
    transition: 0.5S ease-in-out;
}

.chat-tabs .nav-tabs a.active:before {
    content: "";
    background: #AF0612;
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.chatuser_details {
    text-align: left;
    width: 100%;
    position: relative;
}

span.online {
    width: 8px;
    height: 8px;
    background: #AF0612;
    display: block;
    border-radius: 100%;
    position: absolute;
    right: 0;
}

.chat-tabs .user_name {
    font-weight: 600;
    margin-bottom: 3px;
    display: block;
    word-break: break-all;
}

.chat-tabs .user_desc {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}

span.msg_desc {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.chatuser_details .user_desc .user-des,
span.availability {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-tabs .user_desc span.time_show {
    font-weight: 400;
    font-size: 11px;
    min-width: 75px;
    text-align: right;
    line-height: 1.2;
}

.viewListUser.data {
    max-height: 45vh;
    overflow-y: auto;
}

.user-online span.online.onimg {
    bottom: 4px;
    box-shadow: 0 2px 4px #2ec72e;
    width: 11px;
    height: 11px;
}


/* Chat board */
.viewChatBoard {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-height: 55vh;
}

.headerChatBoard {
    border-bottom: 1px solid rgb(44 37 27 / 30%);
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.chat-header {
    width: 100%;
    display: flex;
    align-items: center;
}

.headerChatBoard .slider-content .dropdown {
    position: relative;
    background: transparent !important;
}

.headerChatBoard .slider-content .dropdown:hover {
    background: #af0612 !important;
}

.headerChatBoard .slider-content .dropdown>button {
    color: #af0612 !important;
}

.headerChatBoard .slider-content .dropdown:hover>button {
    color: #fff !important;
}

.textHeaderChatBoard {
    font-weight: bold;
    color: #203152;
    margin-left: 10px;
}

.viewListContentChat {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-top: 10px;
    padding-bottom: 20px;
    max-height: 45vh;
    min-height: 30vh;
}

.viewListContentChat::-webkit-scrollbar-track {
    padding: 2px 0;
}

.viewListContentChat::-webkit-scrollbar {
    width: 6px;
}

.viewListContentChat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e8e8e8;
}

.viewListUser.data::-webkit-scrollbar {
    width: 0;
}


.viewBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
}

.viewBottom img.gallery_select {
    max-width: 28px;
    margin-right: 25px;
    cursor: pointer;
}

.viewInputGallery {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 10px;
    width: 30px;
}

img.icOpenSticker {
    margin-right: 15px;
    cursor: pointer;
}

img.icSend {
    cursor: pointer;
}

.viewInput {
    flex: 1;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    height: 30px;
}

.viewInput:focus {
    outline: 0;
}

input::placeholder {
    color: rgb(199, 199, 199);
}

/* View item message */
.viewItemRight {
    width: auto;
    height: auto;
    background-color: #f2d1d3;
    align-self: flex-end;
    margin-top: 10px;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 250px;
    max-width: 500px;
}

.viewItemRight2 {
    width: auto;
    height: auto;
    background-color: #f2d1d3;
    align-self: flex-end;
    margin-top: 10px;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 250px;
    max-width: 500px;
    width: 100%;
}

.viewItemRight2 div {
    margin-bottom: 0 !important;
}

.right-imageleft {
    width: auto;
    height: auto;
    background-color: #f2d1d3;
    align-self: flex-end;
    margin-top: 10px;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 300px;
    max-width: 500px;
}

button.image-modal.btn.btn-primary {
    background: transparent !important;
    padding: 0;
    margin: 0;
    border: 0 !important;
    width: 100%;
    outline: none;
}

.left-imagesingle {
    width: auto;
    height: auto;
    background-color: #EEEEEE;
    align-self: flex-start;
    margin-top: 10px;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 250px;
    max-width: 350px;
    position: relative;
}

.more-images {
    max-width: 43%;
    background: rgb(0 0 0 / 50%);
    color: #fff;
    font-weight: 600;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: absolute;
    right: 20px;
    bottom: 33px;
    width: 100%;
}

.left-imagesingle img.group-images {
    width: 50%;
    height: 140px;
    object-fit: cover;
    padding: 5px;
}

.post-userchatdesc h4 {
    color: #AF0612;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.post-chat {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.post-chat h6 {
    font-weight: 700;
}

.post-userchatdesc p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.post-userchatimg a {
    display: block;
}

.post-userchatimg img.imgItemRight {
    max-height: 150px;
    min-height: 150px;
}

.post-userchatimg {
    width: 100%;
    height: 100%;
}

.viewItemRight3 {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    text-align: left;
}

.viewWrapItemLeft {
    margin-top: 10px;
}

.viewWrapItemLeft2 {
    width: auto;
    align-self: flex-start;
    text-align: left;
    margin-top: 10px;
}

.viewWrapItemLeft3 {
    flex-direction: row;
    display: flex;
    margin-bottom: -15px;
}

.viewItemLeft {
    width: auto;
    height: auto;
    background-color: #EEEEEE;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 250px;
    max-width: 500px;
}

.viewItemLeft2 {
    width: auto;
    height: auto;
    background-color: #EEEEEE;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 250px;
    max-width: 500px;
    width: 100%;
}

.viewItemLeft2.userleft {
    margin-bottom: 10px;
}

.viewItemLeft3 {
    width: auto;
    height: auto;
    background-color: #EEEEEE;
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;
    min-width: 250px;
    max-width: 300px;
}

.peerAvatarLeft {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    object-fit: cover;
    align-self: flex-end;
    display: none;
}

.textContentItem {
    font-size: 14px;
    word-break: break-all;
}

.viewListContentChat div:last-child {
    margin-bottom: 10px;
}

.viewBottom button {
    background: transparent;
    border: 0;
    padding: 0;
    outline: none;
}

.viewWrapItemLeft.peerpost .viewWrapItemLeft3 {
    justify-content: flex-end;
    margin: 0;
}

.viewWrapItemLeft.peerpost .viewWrapItemLeft3 .viewItemLeft2.row {
    margin: 0 !important;
}

.viewWrapItemLeft.peerpost {
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 0;
}

.viewItemRight2.userright,
.viewItemRight2.userleft {
    width: auto;
}

.post_usershare .chatuser_details span.user_desc {
    font-size: 12px;
    font-weight: 400;
    word-break: break-all;
}

/* Item image */
.post-userchatimg div {
    height: 100%;
    margin: 0 !important;
}

.post-userchatimg {
    margin: 0 !important;
}

.imgItemRight,
.imgItemLeft {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 150px;
    min-height: 150px;
}

.equalheight {
    margin: 0 !important;
}

.textTimeLeft {
    color: #808888;
    font-size: 10px;
    margin-left: auto;
    text-align: right;
    display: block;
}

.viewStickers {
    display: flex;
    border-top: 1px solid #e8e8e8;
    height: 100px;
    align-items: center;
    justify-content: space-around;
}

.imgSticker {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

span.delivered {
    margin-left: 6px;
    font-size: 16px;
}

.unsend-btn {
    width: 100%;
    margin: 0 !important;
}

.unsend-btn button.primary-btn {
    background: #2C251B;
    color: #fff;
    width: 100%;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
}

.post_usershare {
    display: flex;
    align-items: center;
    min-width: 300px;
}

/* Say hi */
.viewWrapSayHi {
    text-align: center;
    padding: 80px 20px;
    text-transform: uppercase;
}

.viewWrapSayHi img.imgWaveHand {
    margin: 0 auto;
    margin-bottom: 25px;
    max-width: 120px;
}

.viewWrapSayHi h3 {
    margin-bottom: 10px;
}

.no-users {
    padding: 20px 0;
    text-align: center;
    width: 100%;
    font-weight: 600;
}

.notification {
    max-width: 570px;
    margin: 0 auto;
    padding: 20px 0 100px;
}

.notification-data ul {
    max-height: 100vh;
    overflow-y: auto;
}

.notification-data ul li {
    background: #EEEEEE;
    margin: 0 0 3px;
    border-bottom: 1px solid rgb(112 112 112 / 30%);
    padding: 10px 15px;
}

.notification-data ul li.active,
.notification-data ul li:focus {
    background: rgb(255, 255, 255);
}

.notification-data ul li a {
    width: 100%;
}

.notification-data ul li .modal-profile {
    display: flex;
    align-items: center;
}

.notify-details {
    width: 100%;
    padding-left: 15px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.notify-details .notify_content {
    max-width: 75%;
    word-break: break-word;
}

.notify_content h5 {
    margin-bottom: 3px;
}

.notification-data ul::-webkit-scrollbar {
    width: 10px;
}

.notification-data ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.notification-data ul::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 10px;
}

.notify_btn,
.red-btn {
    border: 1px solid #AF0612;
    background: transparent;
    color: #AF0612;
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0;
    padding: 4px 15px;
    min-width: auto;
    font-size: 12px;
    margin-top: 8px;
}

.notify_btn:hover,
.notify_btn:focus,
.red-btn:hover {
    background: #AF0612;
    color: #fff;
    border: 1px solid #AF0612;
}

.red-btn {
    padding: 4px 35px;
}

.notificationview {
    max-width: 400px;
    margin: 0 auto;
}

.viewed {
    text-align: center;
    margin-bottom: 40px;
}

.viewed h3 {
    margin-bottom: 40px;
}

.viewed p span {
    color: #AF0612;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-right: 4px;
}

.viewed p {
    font-weight: 600;
    margin-bottom: 15px;
}

.premium-view ul li {
    display: inline-block;
    position: relative;
    width: 65px;
    height: 65px;
}

.premium-view ul li img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 100%;
    border: 1px solid #fff;
    position: absolute;
    top: 0;
}

.premium-view ul li:nth-child(2) img {
    left: -15px;
}

.premium-view ul li:nth-child(3) img {
    left: -30px;
}

.premium-view {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.premium-view ul {
    min-width: 195px;
}

.trial {
    text-align: center;
}

.trial p {
    font-weight: 400;
    margin-bottom: 15px;
}

.trial p a {
    color: #AF0612;
    font-weight: 600;
}

.notification-viewdata .modal-profile {
    display: flex;
    align-items: center;
    width: 100%;
}

.notification-viewdata ul li {
    padding: 30px 0;
    border-bottom: 1px solid #E2E8ED;
}

.notification-viewdata ul li a {
    width: 100%;
}

.notification-viewdata {
    padding: 40px 0 80px;
}

.share_datamodal .modal-content {
    background: #fff;
    border-radius: 60px;
}

.share_datamodal .modal-content .modal-body {
    padding: 70px;
}

.share_datamodal button.closebutton {
    right: 35px;
    top: 35px;
    border-radius: 100%;
}

.sharedata_modal textarea {
    border: 0;
    padding: 20px 0 0;
}

.share-user img {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: 4px solid #9E8D6E;
}

.share_usersdata ul li button {
    width: auto;
}

.share_usersdata ul li {
    text-align: left;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.unblock-btn {
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
    padding: 10px;
}

.blocked-account {
    border-top: 1px solid rgb(44 37 27 / 30%);
    padding-top: 35px;
}

.blocked-account h3 {
    margin-bottom: 10px;
}

.blocked-account p {
    margin-bottom: 30px;
}

.blocked-account .btn {
    width: 100%;
}

.sitch-toggle {
    border-radius: 0;
    background: #eee;
    border-bottom: 1px solid #2C251B;
    font-size: 18px;
    padding: 18px 30px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.sitch-toggle .btn {
    min-width: auto;
    font-weight: 500;
    font-size: 12px;
    line-height: 0.5;
    text-transform: none;
    border-radius: 40px;
    color: transparent;
}

.sitch-toggle span.switch-handle {
    width: 18px;
    padding: 0;
    right: 10px;
    position: relative;
    top: 1px;
    height: 18px;
}

.sitch-toggle .switch.btn.off span.switch-handle {
    right: auto;
    left: 10px;
}

.switch.btn.off .switch-group span.btn-xs {
    background: #BCBBBB;
}

.sitch-toggle .off.btn-light {
    border-color: #BCBBBB !important;
    background: #BCBBBB;
}

.sitch-toggle .btn-success {
    background-color: #1BA444;
    border-color: #1BA444;
}

.switch.btn.off.btn-light.btn-xs+.switch-header {
    opacity: 0.5;
}

.scrolltopchat {
    position: absolute;
    bottom: 65px;
    right: 20px;
    z-index: 99;
}

.scrolltopchat button {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-shadow: 0 0px 23px 3px rgb(0 0 0 / 20%) !important;
    min-height: 40px;
    text-align: center;
    line-height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 40px;
    font-size: 18px;
    color: #AF0612;
}

.scrolltopchat button svg {
    transform: rotate(-90deg);
}

.scrolltopchat button:hover,
.scrolltopchat button:focus {
    background: #AF0612;
    color: #fff;
}


/* payments page css  */
.payments {
    padding: 20px 0 40px;
}

.premium-plan {
    position: relative;
    padding: 20px;
}

.order_summary {
    background: #f2d1d3;
    margin-bottom: 50px;
}

.order_summary h3 {
    font-size: 24px;
    line-height: 1.2;
    padding: 20px;
}

.premium-plan span.premium {
    position: relative;
    margin-left: 20px;
    font-weight: bold;
}

.premium-plan p {
    font-size: 16px;
    font-weight: 600;
}

.order_summary table.table {
    margin: 0;
}

.order_summary table.table tbody tr td {
    border-bottom: 1px solid;
    padding: 20px 0;
    font-size: 16px;
    font-weight: 500;
}

.order-table {
    padding: 0 20px;
}

.order_summary table.table tbody tr td.chf {
    text-align: right;
}

.order_summary table.table tfoot td {
    font-size: 24px;
    font-weight: 600;
    padding: 20px 0;
    border: 0;
}

.order_summary table.table tfoot td.chf-foot {
    text-align: right;
    color: #AF0612;
    font-weight: bold;
}

.subscribe {
    padding: 30px 20px 40px 20px;
}

.subscribe button.primary-btn {
    width: 100%;
}

.cards_details .setting-accordian .card-header {
    border: 0;
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.cards_details .setting-accordian .card-header img {
    margin-right: 15px;
    height: 28px;
    width: 38px;
    object-fit: contain;
}

.cards_details .setting-accordian .card-header h5 {
    font-size: 20px;
}

.cards_details .setting-accordian .card-body {
    padding: 0 30px 30px;
    background: #eee;
}

.cards_details {
    margin-bottom: 50px;
}

.user-detail p {
    font-size: 13px;
    position: relative;
    left: 2px;
}

.SRLContainer .SRLThumbnailsContainer {
    margin-bottom: 40px;
}

.viewListContentChat.blocked-chat {
    opacity: 0.5;
    pointer-events: none;
}

.text {
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: #AF0612;
    cursor: pointer;
    font-weight: bold;
}

.image__modalopen .modal-content {
    border-radius: 0;
    background-color: #f2d1d3;
    padding: 10px;
}

.image__modalopen .modal-body {
    padding: 0;
    border-radius: 0;
    max-height: 70vh;
    overflow-y: auto;
}

.image__modalopen .modal-body::-webkit-scrollbar {
    width: 5px;
}

.image__modalopen .modal-body::-webkit-scrollbar-thumb {
    background: #2C251B;
}

.share-imagechat p {
    background: rgb(255 255 255 / 60%);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    line-height: 18px;
    color: #2C251B;
}

.share-imagechat {
    background: rgb(44 37 27 / 80%);
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 20px 20px 10px 10px;
    border-bottom-left-radius: 26px;
}

.share-imagechat p svg {
    transform: scaleX(-1);
}

.reply-btnchat {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}

.chat-modalbody img.modalchat_img {
    width: 100%;
}


.day-lists {
    position: relative;
    text-align: center;
    margin: 0 10px;
    font-size: 13px;
    font-weight: 500;
}

.day-lists::after {
    content: "";
    width: 45%;
    height: 1px;
    background-color: #d5d5d5;
    position: absolute;
    right: 0;
    top: 9px;
    z-index: -1;
}

.day-lists::before {
    content: "";
    width: 45%;
    height: 1px;
    background-color: #d5d5d5;
    position: absolute;
    left: 0;
    top: 9px;
    z-index: -1;
}

.edit-artworkimg {
    position: relative;
}

.exhibition-detail .edit-artworkimg .edit-img {
    background: rgb(175 6 18 / 80%);
    display: inline-block;
    color: #fff;
    padding: 10px 13px 13px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0px 0px 20px;
    font-size: 20px;
    border: 0;
    min-width: auto;
    width: auto;
}

.exhibition-detail .edit-artworkimg .edit-img:hover {
    background: rgba(0, 0, 0, 0.6);
}

.footer-logo {
    max-width: 130px;
}

span.navbar-brand {
    max-width: 450px;
}

.point-img {
    width: 35px;
    height: 35px;
    background: #0202ff;
    border-radius: 100%;
    margin-right: 18px;
}

.exhibition-detail .edit-artworkimg {
    margin-bottom: 10px;
}

.artboard-sliderdata img {
    max-height: 400px;
    min-height: 400px;
    width: 100%;
    object-fit: cover;
}

.edit-artworkmodal .slider-artworkdetails {
    padding: 30px;
    text-align: center;
}

.edit-artworkmodal .slider-artworkdetails .slick-slider {
    margin-bottom: 25px;
}

.edit-artworkmodal .slider-artworkdetails button.btn.primary-btn {
    width: 100%;
}

.downloadmodal .modal-body {
    padding: 8px;
    border-radius: 0;
    position: relative;
    background: #f2d1d3;
}

.dwnload--icon {
    position: relative;
}

.dwnload--icon button.image-modal.btn.btn-primary {
    height: 100%;
}

.dwnload--icon button.download-ico {
    min-width: auto;
    padding: 8px 14px 12px 16px;
    color: #fff;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    background: rgb(175 6 18 / 80%);
    border-radius: 0 0 0 25px;
    text-align: center;
}

.dwnload--icon button.download-ico a {
    color: #fff;
}

.dwnload--icon button.download-ico:hover {
    background: #fff;
    color: #af0612;
}

.dwnload--icon button.download-ico:hover a {
    color: #af0612;
}

.slider-artworkdetails h3 {
    margin-bottom: 20px;
}

.slider-artworkdetails .artwork-imglist {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.slider-artworkdetails .artwork-imglist .check-artwork {
    width: 50%;
    padding: 10px;
}

.slider-artworkdetails .artwork-imglist .check-artwork span.checkmark {
    top: 20px;
    right: 20px;
}

.slider-artworkdetails .artwork-imglist .check-artwork img {
    max-height: 250px;
    min-height: 250px;
}

.artworklist-btn {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
}

.artworklist-btn button.primary-btn {
    margin: 10px;
}

section.why_artboxy {
    padding-bottom: 60px;
}

.why-artboxy-review .artboxy-review--content {
    justify-content: center;
}

.why-artboxy-review .artboxy-review--content .review_title {
    margin: 0 0 15px;
    font-family: 'Barlow', sans-serif;
    line-height: 1.3;
    font-weight: 600;
}

.why-artboxy-review .artboxy-review--content p {
    font-family: 'Barlow', sans-serif;
}

.artboxy-client-image img {
    border-radius: 100%;
}

.why-artboxy-review {
    align-items: center;
}


.tablesub-btns button {
    background: #f0f0ea !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 0;
    color: #2C251B !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 14px;
    margin: 1px;
    min-width: auto;
    font-size: 13px
}

.tablesub-btns button.active {
    border-color: #af0612 !important;
}

.subscription-accordian nav.table-subscript {
    border: 0;
}

.tablesub-btns {
    margin-bottom: 15px;
}

.standard-tab table.table svg {
    font-size: 20px;
}

.standard-tab table.table svg.true {
    color: limegreen;
}

.standard-tab table.table svg.false {
    color: #ab000e;
}

.standard-tab table.table td {
    min-width: 97px;
    padding: 6px 10px;
    font-weight: 500;
    font-size: 13px;
}

.standard-tab table.table td.center {
    text-align: center;
    vertical-align: middle;
}

.standard-tab {
    display: flex;
}

.standard-tab table.table {
    max-width: 68%;
}

.subscribe-us {
    max-width: 35%;
    padding: 0 30px;
    min-width: 280px;
}

.month-yearly {
    text-align: center;
}

.month-yearly h6 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
}

.month-yearly .radiobtn label.radio-custom-label {
    padding: 10px;
    font-size: 13px;
    border: 1px solid #AF0612;
}

/* .month-yearly .radiobtn label.radio-custom-label::before{
    display: none;
} */
.apply-coupen {
    text-align: center;
    margin: 0 0 20px;
}

.apply-coupen h5 {
    margin-bottom: 10px;
    font-size: 14px;
}

.apply-coupen form {
    width: 100%;
}

.apply-coupen form .coupen.form-group {
    width: 100%;
    margin-bottom: 8px;
}

.apply-coupen .form-check {
    text-align: left;
}

.apply-coupen form .coupen.form-group input {
    border-radius: 0;
    padding: 8px 13px;
    font-size: 14px;
    border: 1px solid #212529;
}

.apply-coupen .form-check input[type=checkbox] {
    margin-top: 2px;
    transform: scale(1.05);
}

.form-check-input:checked {
    background-color: #AF0612;
    border-color: #AF0612;
}

.apply-coupen form button.btn.btn-primary {
    background: #eee;
    border: 1px solid #212529;
    color: #212529;
    padding: 8px 20px;
    min-width: 150px;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
    margin-top: 3px;
}

.apply-coupen form small.error-msg {
    color: red;
}

.subscribe-us button.secondary-btn {
    min-width: 100%;
    padding: 10px;
    color: white;
    text-transform: uppercase;
    background: #AF0612;
}

.subscribe-us button.secondary-btn:hover {
    min-width: 100%;
    padding: 10px;
    color: white !important;
    text-transform: uppercase;
    background: #AF0612 !important;


}

.tabtext {
    font-weight: 500;
    font-size: 13px;
}

.active-header {
    background: #AF0612;
    color: #fff;
    text-align: center;
    padding: 15px 5px;
    border-bottom: 1px solid #212529;
}

.active-header h4 {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.1;
}

.active-premium .active-body {
    background: #EEEEEE;
    padding: 15px 5px;
}

.active-premium .active-body .dates {
    padding: 0 15px;
}

.active-premium .active-body h5 {
    font-weight: 700;
    font-size: 13px;
    margin: 7px 0;
}

.active-premium .active-body h5 span {
    font-weight: 500;
}

.active-premium .active-body .dates.brdr-btn {
    border-bottom: 1px solid #9c9c9c;
    padding-bottom: 6px;
    margin-bottom: 15px;
}

.sub-header {
    text-align: center;
    margin-bottom: 25px;
}

.sub-header h6 {
    margin: 10px 0 0;
    font-weight: 600;
}

.sub-header h6 a {
    color: #AF0612;
}

.note-artwork {
    margin-top: 15px;
    font-weight: 500;
}

.welcome-modaltext h4 {
    margin-bottom: 20px;
    color: #AF0612;
}

.welcome-btns {
    display: flex;
    align-items: center;
    margin: 30px 0 50px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.welcome-btns button.btn.btn-primary {
    background: #AF0612;
    color: #fff;
    font-size: 14px;
    width: auto;
    border-color: #AF0612;
    font-weight: 500;
    min-width: 150px;
    padding: 10px 20px;
    margin: 5px;
}

.welcome-modaltext p {
    margin-bottom: 15px;
}

.password-field {
    position: relative;
}

.password-field span.pass-show {
    position: absolute;
    right: 20px;
    font-size: 16px;
    top: 5px;
}


.masonry-Gallery.searchgallery .list-gallery .masonrypaint-content .accordian-search {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent linear-gradient(0deg, #FFFFFF 20%, #FFFFFF00 100%);
}

.masonry-Gallery.searchgallery .list-gallery .masonrypaint-content {
    height: 100%;
}

.submission-modal .modal-body {
    padding: 70px 50px;
}

.submission-modal .modal-body p {
    font-size: 16px;
}

.setting-modal.modal .modal-footer button.primary-btn {
    background: #AF0612 !important;
    color: #fff !important;
    padding: 10px 20px;
    min-width: 100px;
    border: 1px solid #AF0612 !important;
    margin: 0 10px;
}

.setting-body {
    text-align: left;
}

.setting-body p {
    margin-bottom: 10px;
}

.setting-body ul {
    list-style: square;
    margin-left: 30px;
    margin-bottom: 10px;
}

.setting-body h5 {
    margin-bottom: 20px;
}

.about-head center {
    text-align: left;
    max-width: 350px;
    margin: 0 auto;
    font-weight: 500;
}

/* .about-head {
    margin-top: 40px;
} */


.whybox iframe {
    width: 100%;
    height: 100%;
    max-width: 600px;
    min-height: 320px;
}

.iframe-box {
    text-align: center;
    margin-bottom: 50px;
}

.ul-whybox ul li img {
    max-width: 45px;
    margin-right: 25px;
    width: 100%;
}

.ul-whybox ul li {
    margin-bottom: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.ul-whybox {
    max-width: 750px;
    margin: 0 auto;
    padding-bottom: 80px;
}

.community-info {
    padding-bottom: 60px;
}

section.privacy-section {
    padding-bottom: 60px;
}

section.privacy-section h4 {
    margin-bottom: 12px;
    font-size: 17px;
}

section.privacy-section p {
    margin-bottom: 15px;
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText,
.CookiebotWidget-main-logo {
    display: none !important;
}

.cards_details.updatecard {
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 0 9px 4px rgb(0 0 0 / 5%);
    margin-bottom: 30px;
}

.cards_details .card-body hr.mb-4 {
    margin: 0 !important;
}

.premium-profile {
    padding-bottom: 70px;
}

.premium-profile p {
    margin-bottom: 15px;
}

.premium-profile h6.submit_verify {
    padding-bottom: 30px;
}

.premium-profile button.btn {
    margin-top: 25px;
}

.price-post {
    display: block;
    margin: 0;
    padding: 6px 0;
    font-size: 12px;
    font-weight: 600 !important;
}

h6.price-post {
    padding: 8px 0;
    font-size: 13px;
}

.secondary-btn.trial {
    width: 100%;
    background: #AF0612 !important;
    color: #fff !important;
    cursor: inherit;
}

.subscription-plans .order-selection label.radio-custom-label {
    padding: 11px 10px;
    font-size: 12px;
    background: #fff;
    margin-bottom: 10px;
}

.subscription-plans .radiobtn input:checked+.radio-custom-label {
    background: #f2d1d3;
    color: #000;
}

.subscription-plans .order-selection label span.save {
    position: relative;
    top: 2px;
    padding: 0;
    display: block;
    font-size: 9px;
    color: #AF0612;
    line-height: 1;
}

.subscription-plans .form-check-input:checked {
    background-color: #fff;
    background-image: url(../images/dot.svg) !important;
    background-position: center;
    background-size: contain;
    border-color: rgba(0, 0, 0, .25);
}

.subscription-plans input,
.subscription-plans input:focus {
    border-color: rgba(0, 0, 0, .25);
    margin-left: 0 !important;
    margin-top: 2px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 100% !important;
    padding: 0;
}

.subscription-plans .form-check {
    padding: 15px 0px 10px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.active-body button.btn.primary-btn {
    width: 100%;
    margin-bottom: 8px;
    font-size: 13px;
    padding: 10px;
    text-transform: uppercase;
    min-width: auto;
}

.upgrade-btn {
    margin: 25px 9px 0;
}

.active-body .cancel.btn.btn-primary {
    min-width: auto;
    padding: 5px 0 0;
    border: 0 !important;
    background: transparent !important;
    color: #2C251B !important;
    font-size: 12px;
}

.premiumsubmit-details {
    padding: 0 5px;
}

.bg-artboxy {
    background-color: #AF0612;
}

.progressbar .progress {
    height: 12px;
    font-size: 10px;
    font-weight: 600;
    max-width: 500px;
    margin: 0 auto;
}

.progressbar {
    padding-top: 30px;
}

.plan-list {
    text-align: center;
    margin-bottom: 10px;
}

.subscription-plans .order-selection {
    margin-bottom: 25px;
}

.cancelsub-btn {
    border-top: 1px solid #000;
    padding-top: 20px;
    margin-top: 10px;
    text-align: center;
}

.cancelsub-btn button.btn.primary-btn {
    width: auto;
    margin: 0 auto;
    font-size: 12px;
    padding: 8px 15px;
}

.home-artboxy {
    padding: 0 10px 60px;
}

.home-artboxy .heading {
    padding: 80px 20px;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.home-artboxy .heading::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.home-artboxy .heading h2 {
    margin-bottom: 10px;
    position: relative;
    color: #fff;
}

.home-artboxy .heading p {
    position: relative;
    color: #fff;
}

.home-head {
    padding-bottom: 20px;
    text-align: center;
}

.platform p {
    padding-bottom: 10px;
}

.platform p a {
    font-weight: 500;
    color: #AF0612;
}

.platform ul {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.platform ul li {
    display: inline-block;
    max-width: calc(25% - 20px);
    text-align: center;
    padding: 30px 25px;
    vertical-align: top;
    margin: 10px 10px;
    position: relative;
    box-shadow: 0 6px 10px 4px rgb(239 239 239 / 80%);
    border-radius: 4px;
    width: 100%;
}

.platform ul li span {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
}

.platform {
    padding-bottom: 50px;
}

.home-box h4 {
    margin-bottom: 10px;
}

.home-box {
    text-align: center;
    box-shadow: 0 6px 10px 4px rgb(239 239 239 / 80%);
    border-radius: 4px;
    padding: 30px 25px 15px;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.home-box p {
    padding-bottom: 10px;
}

.why-arthome {
    padding-bottom: 30px;
}


.home-popup .modal-content .modal-body {
    padding: 0;
    position: relative;
}

.offer_profile {
    position: relative;
}

.offer_profile img {
    width: 100%;
    object-fit: contain;
}

span.image-map__map.image-map_map_span-0 {
    width: 50px !important;
    height: 50px !important;
    right: 20px;
    top: 20px !important;
    cursor: pointer;
    z-index: 10;
    display: block;
    left: auto !important;
}

span.image-map__map.image-map_map_span-1 {
    height: 42px !important;
    width: 190px !important;
    bottom: 25px;
    transform: translateX(-50%);
    left: 51% !important;
    cursor: pointer;
    z-index: 10;
    display: block;
    top: auto !important;
}

/* ===================== mobile modal css start here ======================= */
.modal.loading-modal {
    top: auto;
    bottom: 0;
    margin: 0;
    height: auto;
    padding: 0 !important;
}

.loading-modal .modal-dialog {
    margin: 15px auto 0;
    max-width: 700px;
}

.loading-modal .modal-content {
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 5px 10px #2c251b26;
}

.loading-modal .modal-content .modal-header .modal-title {
    font-size: 1.25rem;
}

.loading-modal .modal-header button.close {
    border: 1px solid #000;
    font-size: 30px;
    line-height: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    text-align: center;
    background: transparent;
}

.loading-modal .modal-content .modal-header button.close span.sr-only {
    display: none;
}

.loading-modal .modal-content .modal-header button.close span {
    position: relative;
    top: -2px;
}

.open-app_in {
    padding: 15px;
}

.open-app_in ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.open-app_in ul li .app-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
}

.open-app_in ul li img {
    max-width: 45px;
}

.open-app_in ul li button.btn {
    min-width: auto;
    border-radius: 30px;
    background: #000;
    color: #fff;
}

.open-app_in ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 6px;
}


/* =================== contests css start ================== */
.mb2 {
    margin-bottom: 40px;
}

.contests-wrapper {
    padding-bottom: 40px;
}

.gallery-contest {
    position: relative;
    max-width: 320px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gallery-contest .heading {
    padding-bottom: 40px;
}

.gallery-contest .heading h3 {
    color: #AF0612;
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 30px;
}

.gallery-contest .heading p {
    font-weight: 700;
}

.contests-row .col-md-6 {
    position: relative;
}

.contests-row .col-md-6:first-child:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 1px;
    height: 75%;
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
}

.contest-gallery-slider {
    padding-bottom: 60px;
}

.contest-gallery-slider img {
    width: 100%;
    min-height: 200px;
    max-height: 250px;
    object-fit: cover;
}

.primary-btn.btn-small {
    padding: 8px 20px;
}

.contest-list .contest-tab {
    border: 0;
    justify-content: center;
    gap: 20px 100px;
    padding-bottom: 45px;
}

.contest-button {
    min-height: 70px;
}

.contest-list .contest-tab .nav-link {
    font-weight: 700;
    font-size: 23px;
    color: #AF0612;
    border-radius: 0;
    border: 0;
    padding: 0 0 5px 0;
    position: relative;
    width: fit-content;
    margin-left: auto;
}

.contest-list .contest-tab .nav-item {
    width: 100%;
    max-width: 400px;
}

.contest-list .contest-tab .nav-link::after {
    content: "";
    background: #AF0612;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
    opacity: 0;
}

.contest-list .contest-tab .nav-item:last-child {
    text-align: left;
}

.contest-list .contest-tab .nav-item:last-child .nav-link {
    margin-left: 0;
}

.contest-list .contest-tab .nav-link.active::after {
    opacity: 1;
}

.contest-list {
    padding-bottom: 80px;
}

.list-content {
    display: flex;
    gap: 50px 100px;
    justify-content: center;
    flex-wrap: wrap;
}

.contests--box {
    background: rgba(217, 217, 217, 0.26);
    max-width: 400px;
    width: 100%;
}

.contests--image img {
    width: 100%;
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
}

.contests--data {
    padding: 15px;
}

.contests--data h3 {
    color: #AF0612;
    font-weight: 700;
    margin-bottom: 5px;
}

.contests--data span.date {
    display: block;
    font-weight: 700;
    font-size: 11px;
    color: #000000;
    margin-bottom: 10px;
}

.contests--data p {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 36px;
}

.contests-button {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 10px 20px;
}

.contests-button .btn-primary {
    padding: 12px 15px;
    min-width: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    line-height: 1;
    font-weight: 700;
    font-size: 15px;
}

.contests-button .btn-primary img {
    max-height: 15px;
    margin-right: 5px;
}

.contests-button .btn-primary span {
    font-size: 10px;
    letter-spacing: 0;
    font-weight: 600;
}

.contests-button .btn-primary.success-btn,
.btn-primary.success-btn {
    background: #30BE30;
}

.contests-button .btn-primary.error-btn,
.btn-primary.error-btn {
    background: #AF0612;
    color: #fff;
    border-color: #AF0612;
}

.contests-button .btn-primary.winner-btn {
    background: #DCE023;
}

.contests-button .btn-primary.disabled-btn {
    background: #D9D9D9 !important;
    color: #000 !important;
    opacity: 0.7;
}

.contents-modal-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: 50px;
}

.contents-modal-btn .btn {
    padding: 12px 30px;
    font-size: 16px;
    font-weight: 600;
}

.contents-modal-btn .secondary-btn {
    background-color: #000;
    color: #fff;
    border: 2px solid #2C251B;
}

.follow-head.contest-head {
    margin: 45px 0 30px;
    justify-content: center;
}

.contest-gallery-slider button.slick-arrow {
    width: 25px;
}

.slide-number {
    padding-block: 10px 40px;
}

.contest-gallery-slider.winner-slider {
    padding: 0;
    max-width: 350px;
    margin: 0 auto;
}

.contest-gallery-slider .content {
    padding: 20px 0;
}

.contest-gallery-slider .content h3 {
    color: #AF0612;
    margin-bottom: 12px;
}

ul.slick-dots {
    position: relative;
    bottom: 0;
}

.slick-dots li {
    width: 15px;
    height: 15px;
}

.slick-dots li button {
    border: 1px solid #AF0612;
    background: transparent;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
}

.slick-dots li.slick-active button {
    background: #AF0612;
}

.slick-dots li button::before {
    display: none;
}

.premium-contest h3 {
    margin-bottom: 3rem;
    font-size: 22px;
    font-weight: 700;
}

.premium-padding .modal-body {
    padding: 80px 70px;
}

.winnercontest-modal button.slick-arrow {
    width: 25px;
}

.exhibition .sub-header h2 {
    color: #AF0612;
}

.profile-name .name h5 {
    font-weight: 400;
}

.award-slider {
    margin-bottom: 2.5rem;
}

.award-box.profile-artwork {
    margin-bottom: 1rem;
    padding: 10px 12px;
}

h5.winner-list {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #FFD700;
}

.winner-list svg {
    font-size: 20px;
    width: 38px;
}

.award-box.profile-artwork .artboard {
    margin-bottom: 1rem;
}

.award-box .award-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    gap: 10px 15px;
    padding: 0;
    position: relative;
}

.award-text {
    padding-inline: 40px;
}

/* .award-slider .slick-track {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
} */
/* .award-box .award-heading .award-text {
    width: 100%;
} */

.award-box .award-heading svg {
    color: #FFD700;
    font-size: 38px;
    position: absolute;
    left: 0;
}

.award-box .award-heading h4 {
    color: #A0221E;
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    font-weight: 600;
}

.award-box .award-heading h4 span {
    color: #000;
}

.contest-head .follow-artist .follow-text {
    color: #AF0612;
    opacity: 1;
    min-width: auto;
    width: auto;
}

.contest-head .follow-artist .follow-text:before {
    background: #AF0612;
}

.slide-number h4 {
    color: #A0221E;
    font-size: 18px;
}

.follow-head.contest-head .follow-artist {
    text-align: left;
}

.follow-head.contest-head .follow-artist a {
    color: #000;
}

.follow-head.contest-head .follow-artist a:hover {
    color: #AF0612;
}

.follow-head.contest-head .currentbar img {
    width: 30px;
    height: 30px;
    min-width: 30px;
    top: 2px;
    left: 2px;
}

.follow-head.contest-head .currentbar {
    margin: 0;
    display: block;
    width: 34px;
    height: 34px;
    min-width: 34px;
    border-radius: 100%;
}

.Toastify .Toastify__toast-container--bottom-center {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    bottom: auto;
    width: 100%;
    max-width: 500px;
}

.Toastify__toast.auto_logout-center {
    margin: 0;
    padding: 40px;
    gap: 15px;
}

.Toastify__toast.auto_logout-center button.Toastify__close-button {
    opacity: 1;
}

.Toastify__toast.auto_logout-center .Toastify__toast-body {
    padding: 0;
}

.remaining-participant-time {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    font-weight: 700;
    margin-top: 12px;
}

.Toastify__toast.artwork_toastify {
    margin: 0;
    padding: 30px;
    gap: 15px;
}

.Toastify__toast.artwork_toastify button.Toastify__close-button {
    opacity: 1;
}

.Toastify__toast.artwork_toastify .Toastify__toast-body {
    padding: 0;
}

/* ================== download page ================ */
.download-box {
    background: #AF0612;
    padding: 20px;
    border-radius: 21px;
    text-align: center;
    color: #fff;
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 40px;
    height: 200px;
}
.download-box .month-year{
    margin-top: 10px;
    margin-bottom: 30px;
}

.download-box h5 {
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    margin: 0;
}

.download-box .view-files {
    min-width: auto;
    width: 100%;
    background: #fff;
    border: 2px solid #000;
    font-size: 18px;
    padding: 12px;
    margin-bottom: 10px;
}

.download-box .view-files:hover {
    background: #000;
    color: #fff;
}

.download-wrapper {
    padding: 40px 0;
}

.download-modal.modal .modal-body {
    padding: 50px;
}

.download_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.download-modal.modal .modal-body h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.download_modal-content {
    padding: 20px 0 0;
}

.download_box h5 {
    font-size: 24px;
    text-align: left;
    line-height: 1.2;
}

.download-modal.modal .modal-dialog {
    max-width: 900px;
}

.download-success {
    min-width: 250px;
    background: #00d000;
    padding: 10px 20px;
    border: 1px solid #00d000;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    display: inline-block;
    width: auto !important;
    font-size: 20px;
}

.download-pending {
    min-width: 250px;
    background: #AF0612;
    padding: 10px 20px;
    border: 1px solid #AF0612;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    display: inline-block;
    width: auto !important;
    font-size: 20px;
}
.download-disabled {
    min-width: 250px;
    background: gray;
    padding: 10px 20px;
    border: 1px solid #808888;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    display: inline-block;
    width: auto !important;
    font-size: 20px;
}


.warning-modal-popup .modal-content {
    padding:20px;
    text-align:center;
    margin:auto;
    align-items: center;
    
}
.warning-modal-popup button.btn.btn-danger {
    border-radius:15px;
    width: 500px;
    margin:10px 0;
    color:#fff
}

.warning-modal-popup button.btn.btn-success {
        border-radius:15px;
        margin:10px 0;
        color:#fff;
    width: 370px;
    
}

.warning-modal-popup .modal-content:first-child {
    font-size:20px;
    font-weight: 500;
    margin-top: 580px;
}
/*Subscription Design*/
.icon.icon-cross.black,
.icon.icon-check.white {
    background: url('../images/icon-subtract.svg') no-repeat center center;
    background-size: auto 32px;
    width: 32px;
    height: 32px;
    display: inline-block;
}
.icon.icon-check.white {
    background-image: url('../images/icon-check-white.svg');
}

.flex-row.setting {
    justify-content: space-between;
    margin-bottom: 40px;
}
.flex-row.setting .nav-item > a {
    font-size: 18px;
    font-weight: 400;
    line-height: 2.11;
    color: #222222CC;
    padding: 10px 0;
    border-bottom: 4px solid transparent;
    border-radius: 0;
}

.flex-row.setting .nav-item > a.active {
    background: transparent;
    border-radius: 0;
    color: #AF0612;
    font-size: 24px;
    line-height: 1.58;
    border-bottom-color: #AF0612;
}

.flex-row.setting .nav-item {
    margin: 0;
}

.flex-row.setting {
    justify-content: space-between;
    margin-bottom: 40px;
}

.flex-row.setting .nav-item > a:hover {
    color: #AF0612;
}

.tab-heading {
    color: #798196;
    font-size: 16px;
    font-weight: 400;
    margin: 50px 0 40px 0;

}
.tab-heading p{
font-family: "Poppins", sans-serif;
font-weight: 400;

}

.tab-heading h2 {
    font-size: 48px;
    font-family: "Source Sans 3", sans-serif;
    font-weight: 700;
    color: #1D2B4F;
    margin-bottom: 23px;
}

.subscription-tab-nav {
    justify-content: center;
    padding: 8px 12px;
    border: 1px solid #E3E3E3;
    max-width: max-content;
    /* width: 100%; */
    margin: 0 auto;
    border-radius: 50px;
}

.subscription-tab-nav .nav-item {
    /* width: 33.33%; */
    text-align: center;
    min-width: 140px;
}

.subscription-tab-nav .nav-item > a {
    border-radius: 0;
    background: transparent;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.06em;
    color: #000000B2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 40px;
    text-transform: uppercase;
}

.subscription-tab-nav .nav-item > a.active {
    background: #AF0612;
    font-weight: 800;
}

.subscription-tab-nav .nav-item > a span {
    font-size: 12px;
    width: 100%;
}
.setting-tab-content{
    max-width: 927px;
    width: 100%;
    margin: 0 auto;
}
.subscription-content {
    margin-top: 60px;
}

.subscription-content .table {
    margin: 0;
    table-layout: fixed;
}
.subscription-content .table td.feature-text,
.subscription-content .table th.left-blank,
.subscription-content .table td.left-blank {
    width: 30%;
}


.subscription-content .table tr td:not(:first-child), 
.subscription-content .table tr th:not(:first-child) {
    width: 20%;
    text-align: center;
}

.subscription-content .table th .info > span {
    display: block;
}
.subscription-content .table th .info > span.time {
    margin: 5px 0;
}
.subscription-content .table th.standard .info > span.price {
    color: #af0612;
}

.subscription-content .table th .info {
    font-weight: 700;
    line-height: normal;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 90px;
}
.subscription-content .table th:not(.active) .info {
    color: #343434;
}

.subscription-content .table th .info .type {
    font-size: 32px;
}

.subscription-content .table .feature-text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.subscription-content .table,
.subscription-content .table td,
.subscription-content .table th {
    border: none;
}

.subscription-content .table th {
    vertical-align: top;
    padding-top: 22px;
    font-family:  "Source Sans 3", sans-serif;
}
.subscription-content .table th.active,
.subscription-content .table td.active {
    background: #af0612;
    color: #fff;
}
table.subscription-body tr:nth-child(2n) td {
    background: #FBFBFB;
}

.subscription-content .table th.active {
    border-radius: 8px 8px 0 0;
}
.subscription-content .table.subscription-footer td.active {
     border-radius: 0 0 8px 8px;
}


.subscription-footer .btn {
    font-size: 16px;
    background: white;
    font-family:  "Source Sans 3", sans-serif;
    border-radius: 8px;
    color: #AF0612;
    font-weight: 600;
    padding: 7px 10px;
    height: 56px;
}

.subscription-footer .current-plan .btn{
    background-color: #BDBDBD;
    color: #fff;
}

.subscription-footer .btn > span {
    display: block;
    font-size: 12px;
}

.subscription-footer .btn.btn-dark {
    background: #A1221E;
    color: #fff;
    border-color: transparent;
    font-weight: 400;
    text-transform: uppercase;
}

.subscription-content .table.subscription-footer td.contact-btn {
    padding-top: 25px;
}

.subscription-content .table.subscription-footer td {
    position: relative;
    padding-bottom: 26px;
}
.active.free-trial > span {
    display: block;
    font-weight: 700;
    margin-top: 5px;
    position: absolute;
    top: auto;
    bottom: 5px;
    width: 100%;
    left: 0;
}

table.table.subscription-body tr > td {
    padding: 24px 18px;
    vertical-align: middle;
}
.subscription-content .scroll-container,
.subscription-content .table-header,
.subscription-content .table-footer {
    padding: 0 17px;
}
.subscription-content .scroll-container {
    /* max-height: 504px;
    overflow-y: auto;
    overflow-x: hidden; */
}


/*Why Artboxy Page*/
@media (min-width: 1200px) {
    .section-testimonials .container {
        max-width: 1600px;
        padding-left: 46px;
        padding-right: 46px;
    }
}
@media (min-width: 1390px) {
    .WhyArtboxy .container {
        max-width: 1390px;
    }
}
.title.text-2xl {
    font-size: 45px;
}
.text-normal {
    font-weight: 400;
}

.btn.theme-primary {
    background-color: #A1221E;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 8px;
    height: 60px;
    line-height: 1;
}
.btn.theme-primary:hover {
    background-color: #000;
}
.btn-link {
    color: #ab000e;
    text-decoration: none;
    font-size: 20px;
    line-height: 1;
}
.btn-link > i {
    margin-left: 8px;
    width: 40px;
    height: 10px;
}

.icon.icon-arrow-long {
    background: url('../images/whyartboxy/icon-arrow-red.svg') no-repeat center center;
    background-size: auto 40px;
    display: inline-block;
}
.icon.icon-facebook,
.icon.icon-instagram {
    background: url('../images/whyartboxy/icon-facebook.svg') no-repeat center center;
    background-size: contain;
    width: 50px;
    height: 50px;
    display: inline-block;
}
.icon.icon-instagram {
    background-image: url('../images/whyartboxy/icon-instagram.svg');
}

.section-top-slider {
    padding-top: 25px;
    padding-bottom: 70px;
}
.section-top-slider .row {
    margin: 0 -34px;
}
.section-top-slider .row > div{
    padding: 0 34px;
}
.section-top-slider .row > div.left-col {
    width: 64%;
}
.section-top-slider .row > div.right-col {
    width: 36%;
}
.section-top-slider .left-col {
    display: flex;
    flex-direction: column;
}
.left-col > .image {
    margin-top: auto;
}
.left-col .content-heading {
    padding-top: 88px;
    padding-bottom: 50px;
    color: #191410;
    max-width: 600px;
    width: 100%;
}
.content-heading > p{
    font-size: 25px;
    margin-bottom: 22px;
    font-weight: 700;
}


.image-slider {
    position: relative;
}
.image-slider .slick-dots{
    position: absolute;
    top: -50px;
    left: auto;
    right: 0;
    text-align: right;
}
.image-slider .slick-dots li button {
    background-color: transparent;
    border-color: #000;
    width: 17px;
    height: 17px;
}
.image-slider .slick-dots li.slick-active button {
    background-color: #000;
}
.section-top-slider .row > div.right-col .image, .section-top-slider .row > div.right-col .image > img {
    height: 100%;
    object-fit: cover;
}

.section-getStarted {
    padding-top: 70px;
    padding-bottom: 70px;
}
.section-head h2 {
    margin-bottom: 30px;
    letter-spacing: 0;
}
.section-head p{
    font-size: 25px;
    font-weight: 700;
}
.section-head p > span {
    color: #A1221E;
}
.getStarted-row {
    padding: 60px 0 35px 0;
}
.getStarted-row .flex-content {
    display: flex;
    align-items: center;
    padding: 45px 0;
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
}
.getStarted-row .col:not(:last-child) {
    border-right: 1px solid #D9D9D9;
}
.getStarted-row .flex-content .counter {
    font-size: 100px;
    font-weight: 100;
    line-height: 1;
}
.getStarted-row .flex-content .text {
    padding-left: 23px;
    font-size: 16px;
}
.getStarted-row .flex-content .text h3 {
    font-size: 25px;
    margin-bottom: 10px;
}
.getStarted-row .flex-content p > strong {
    color: #A1221E;
}
.section-getStarted .actions,
.section-reasons .actions {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 35px 0;
}

.section-how-works {
    padding-top: 50px;
    padding-bottom: 65px;
    background-color: #F9F9F9;
}

.how-to-submit .video-block {
    display: flex;
    max-width: 858px;
    width: 100%;
    margin: 0 auto;
    /* background: #A1221E; */
    color: #fff;
    position: relative;
    margin-bottom: 100px;
}
.how-to-submit {
    max-width: 1060px;
    margin: 60px auto 0 auto;
}
.how-to-submit .video-block > div {
    width: 100%;
}

.how-to-submit .video-block > div.text {
    display: flex;
    align-items: center;
    padding: 40px 60px;
}

.how-to-submit .video-block > div.text h3 {
    font-size: 45px;
    line-height: normal;
    font-weight: 100;
}
.video-main{
    position: relative;
}
.video-main .placeholder-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
    transition: opacity 0.5s;
    cursor: pointer;
    max-height: 483px;
}
.video-main.active .placeholder-image {
    opacity: 0;
    pointer-events: none;
}
.video-main.active .video-play{
    opacity: 0;
}
.video-main .video {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    min-height: 500px;
}
.video-play {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    margin: 0 auto;
    width: 55px;
    height: 52px;
    border-radius: 50%;
    background: transparent;
    border: none;
    background: url('../images/whyartboxy/play-button.svg') no-repeat center center;
    background-size: auto 52px;
    z-index: 2;
}
.video-play .icon.icon-play {
    width: 100%;
    height: 100%;
}

.how-to-submit .or {
    font-size: 45px;
    display: block;
    text-align: center;
    line-height: 1;
}
.advisors-grid{
    padding-top: 40px;
}
.advisors-grid > p {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    line-height: normal;
}
.advisors-list {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    flex-wrap: wrap;
}

.advisors-list .col {
    padding: 0 20px;
    text-align: center;
}

.advisors-list .col .item-inner {
    max-width: 300px;
    margin: 0 auto;
}
.advisors-list .col .item-inner .image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px auto;
}
.advisors-list .col .item-inner .image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.advisors-list .col .item-inner .content{
    font-size: 17px;
    line-height: 1.5;
}
.advisors-list .col .item-inner .content > h3{
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
}

.section-reasons {
    padding-top: 65px;
    padding-bottom: 80px;
}

.grid-reasons {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    margin-top: 60px;
    margin-bottom: 50px;
}

.grid-reasons .col {
    width: 33.33%;
    flex: auto;
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: -1px;
}
.grid-reasons .col:nth-child(3n){
    border-right: none;
}

.grid-reasons .col .item-inner {
    max-width: 251px;
    margin: 0 auto;
    min-height: 230px;
    padding-top: 20px;
    font-size: 16px;
    line-height: 1.4;
}
.grid-reasons .col .item-inner h3 {
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 1;
    text-align: center;
}
.grid-reasons .col .item-inner .icon-image {
    display: block;
    text-align: center;
    margin-bottom: 24px;
}
.section-testimonials {
    background-color: #F9F9F9;
    padding-top: 150px;
    padding-bottom: 150px;
}
.section-social-network{
    padding-top: 138px;
    padding-bottom: 138px;
}


.artist-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
}

.artist-grid .item {
    width: 33.33%;
    padding: 0 16px;
}
.artist-grid .content {
    padding-top: 30px;
    font-size: 16px;
    line-height: 1.4;
    padding-right: 0;
}
.artist-grid .content > p {
    padding-right: 20px;
}
.artist-grid .content > h3 {
    font-size: 40px;
    margin-bottom: 10px;
    line-height: 1;
    font-weight: 400;
}
.artist-grid .content > span {
    color: #2D281F;
    font-size: 25px;
    display: block;
    margin-bottom: 20px;
    line-height: 1;
}
.artist-grid .content .btn-link {
    margin-top: 29px;
    display: inline-block;
}
.artist-grid .content .btn-link:hover {
    color: #ab000e;
}
.artist-grid .image {
    position: relative;
    padding-bottom: 105%;
}
.artist-grid .image > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.section-testimonials .content-col > .content-inner {
    max-width: 350px;
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 140px;
}
.section-testimonials .content-col > .content-inner:before{
    content: "";
    background: url('../images/whyartboxy/icon-qoute.svg') no-repeat center center;
    width: 40px;
    height: 40px;
    background-size: contain;
    position: absolute;
    top: 40px;
    left: 0;
}
.section-testimonials .content-col > .content-inner h2 {
    font-weight: 300;
}
.section-testimonials .content-col > .content-inner  .social-links {
    margin-top: auto;
}
.section-testimonials .content-inner .social-links a{
    margin-right: 20px;
}
.section-testimonials .content-inner .social-links a > i {
    width: 30px;
    height: 30px;
}
.section-social-network .col-image {
    text-align: center;
}
.section-social-network .col-content{
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-social-network .col-content .content-inner {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
}
.section-social-network .col-content h2 {
    font-weight: 300;
    margin-bottom: 23px;
}
.section-social-network .social-links > a {
    margin-right: 20px;
}

/*Popup contect advice*/
.contact-advisor-modal .modal-body {
    padding: 0;
    max-height: 95vh;
    overflow: auto;
}
.contact-advisor-modal .modal-dialog.modal-md {
    max-width: 900px;
    width: 100%;
}
.contact-advisor-modal  .modal-body {
    padding: 0;
}
.contact-advisor{
    display: flex;
}
.contact-advisor .title {
    background-color: #A1221E;
    color: #fff;
    padding: 35px;
    width: 34%;
    display: flex;
    align-items: center;
}
.contact-advisor .actions{
    width: 66%;
    padding: 116px 62px;
}
.contact-advisor .title h2 {
    font-weight: 400;
    text-align: left;
    font-size: 35px;

}

.contact-advisor .actions > button {
    background: #F9F9F9;
    border: 1px solid #F9F9F9;
    font-size: 25px;
    height: 136px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.contact-advisor .actions > button:after{
    content: "";
    background: url('../images/whyartboxy/arrow-bottom.svg') no-repeat center center;
    background-size: contain;
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-left: 10px;
    margin-top: 2px;
    
}

.contact-advisor .actions .icon {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: inline-block;
    margin-right: 25px;
   
}
.icon.icon-chat {
    background-image: url('../images/whyartboxy/icon-chat.svg');
    width: 53px;
    height: 53px;
}
.icon.icon-phone {
    background-image: url('../images/whyartboxy/icon-phone.svg');
    width: 43px;
    height: 43px;
}
.icon.icon-email {
    background-image: url('../images/whyartboxy/icon-email.svg');
    width: 62px;
    height: 62px;
}

.contact-advisor-modal .modal-header {
    padding: 0;
    border: none;
    border-radius: 0;
}

.contact-advisor-modal .modal-header .close {
    position: absolute;
    left: auto;
    right: 2px;
    top: 2px;
    background: transparent;
    border: none;
    font-size: 0;
    background: url('../images/whyartboxy/Close_square.svg') no-repeat center center;
    background-size: contain;
    width: 41px;
    height: 41px;
    z-index: 10;
}

.contact-advisor-modal .modal-header .close .sr-only {display: none;}


/* Pricing table CSS  */


.pricing-table-wrapper {

    height:100%;
   
}
.pricing-table-text{

    max-height: 100%;

}

.pricing-text {
    margin: 20px auto; 
    max-width: 950px;
    text-align: center;
  
}

.pricing-text p{
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
.pricing-text p:first-child{
    margin-bottom: 10px;
}

.subscription-details {
    padding: 14px;
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 500;
    color: #343434;
    margin-top: 40px;
}
.subscription-details .subscription-date{
    display: flex;
    flex-direction: column;
}
.subscription-details .subscription-actions{
    display: flex;
    align-items: center;
    gap: 10px;
}
.subscription-details .subscription-actions .btn {
    border: 1px solid transparent;
    font-size: inherit;
    font-weight: inherit;
    border-radius: 4px;
    min-width: inherit;
}
.subscription-details .btn{
    background-color: #FBFBFB;
    border-color: #FBFBFB;
    color: #343434;
    cursor: pointer;
}
.subscription-details .btn.btn-cancel {
    background-color: transparent;
    border-color: #AF0612;
    color: #af0612;
}
.subscription-details .subscription-date > strong {
    display: block;
    margin-bottom: 3px;
    font-size: 16px;
    padding-bottom: 2px;
}

.subscription-details .subscription-date > span {
    font-size: 13px;
}

.subscription-details .subscription-actions .btn:hover {
    background: #A1221E;
    color: #fff;
}

.subscription-details .subscription-actions .btn.white:hover {
    background: rgba(0,0,0,1);
    color: #fff;
}
.pricing-table-top-header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 20px;
    padding: 0 20px;
}
.pricing-table-top-header .pricing-text{
    margin-top: 5px;
}
.pricing-table-top-header button{
    background-color: #000;
    color: #fff;
    border:1px solid #000;
    border-radius: 6px;
    height: 35px;
    transition: -webkit-filter .2s ease-in;
    transition: filter .2s ease-in;
    transition: filter .2s ease-in, -webkit-filter .2s ease-in;
    width: 100%;
    max-width: 85px;
    cursor: pointer;
    outline: none;
    font-weight: 600;
}
@media (max-width:991px){
    .subscription-details {
        flex-direction: column;
    }
    
    .subscription-details .subscription-date {
        justify-content: center;
        text-align: center;
        flex-direction: row;
        margin-bottom: 15px;
    }
    
    .subscription-details .subscription-date > strong {
        margin-right: 8px;
    }
    
    .subscription-details .subscription-date > span {
        margin-right: 8px;
    }
    .pricing-table-top-header{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .pricing-table-top-header button{
        margin-bottom: 10px;
    }
    .pricing-table-top-header .pricing-text {
        margin-bottom: 5px;
    }
}

@media (max-width:480px){
    .subscription-details .subscription-actions {
        flex-direction: column;
        width: 100%;
    }
    
    .subscription-details .subscription-actions .btn {
        width: 100%;
    }
    
    .subscription-details .subscription-date {
        width: 100%;
        flex-direction: column;
    }
}